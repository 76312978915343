import { getConfigurationValues, getState } from "../../../services/Service";
import { useQuery, useQueryClient } from "react-query";
import { useState, useEffect, useMemo } from "react";
import Select from 'react-select';

export default function ConfigurationSelect({
    configurationItem,
    mutation,
    showLabels = true,
    selectedSingleOption = '',
    selectedOptions = [],
    onSingleSelectChange,
    onMultiSelectChange
}) {
    const queryClient = useQueryClient();
    const [options, setOptions] = useState([]);

    const { data: stateData } = useQuery("State", getState, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    });
    
    const configurationValues = useMemo(() => getConfigurationValues(configurationItem), [configurationItem]);

    useEffect(() => {
        const formattedOptions = Object.keys(configurationValues).map(cvkey => {
            const configValue = configurationValues[cvkey];
            return {
                value: configValue.value,
                label: configValue.description,
                className: configValue.available
                    ? "bg-wam-green"
                    : configValue.possible
                        ? "bg-wam-lightorange"
                        : "bg-wam-red text-wam-white"
            };
        });
        setOptions(formattedOptions);
    }, [configurationValues]); 

    const handleSingleSelectChange = (event) => {
        const value = event.target.value;
        onSingleSelectChange(value);
        checkConflict(configurationItem.classConfiguration, value, true);
    };

    const handleMultiSelectChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        onMultiSelectChange(selectedValues);
        checkConflict(configurationItem.classConfiguration, selectedValues, true);
    };

    const checkConflict = (key, values, selected) => {
        if (!Array.isArray(values)) {
            if (values === "select") {
                const selectedItem = Object.values(configurationValues).filter(it => it.selected);
                mutation.mutate({ key, value: selectedItem[0]?.value, selected: !selectedItem[0]?.selected });
            }

            const isAvailable = configurationValues[values]?.available;
            if (!isAvailable) {
                queryClient.setQueryData("State", (oldState) => ({
                    ...oldState,
                    conflict: { isConflict: true, key, value: values, selected },
                }));
            } else {
                mutation.mutate({ key, value: values, selected });
            }
        } else {
            const conflicts = values.map(value => ({
                value,
                isAvailable: configurationValues[value]?.available,
            }));
            const hasConflict = conflicts.some(conflict => !conflict.isAvailable);
            if (hasConflict) {
                queryClient.setQueryData("State", (oldState) => ({
                    ...oldState,
                    conflict: {
                        isConflict: true,
                        key,
                        values,
                        selected,
                    },
                }));
            } else {
                mutation.mutate({ key, values, selected });
            }
        }
    };

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided, state) => ({
            ...provided,
            borderColor: state.isFocused ? '#003366' : '#d3d3d3',
            boxShadow: state.isFocused ? '0 0 0 1px #003366' : 'none',
            cursor: 'pointer',
            borderRadius: '0.375rem',
            fontSize: '0.875rem',
            color: '#000000',
            backgroundColor: '#ffffff',
        }),
        menu: (provided) => ({
            ...provided,
            width: '100%',
            borderRadius: '0.375rem',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#C6E0B4',
        }),
        option: (provided, { data, isFocused }) => ({
            ...provided,
            padding: '0',
            backgroundColor: isFocused
                ? (data.className.includes('bg-wam-green')
                    ? "#a9d08d"
                    : data.className.includes('bg-wam-lightorange')
                        ? "#f0741e8c"
                        : "#cc0000")
                : (data.className.includes('bg-wam-green')
                    ? "#C6E0B4"
                    : data.className.includes('bg-wam-lightorange')
                        ? "#f185288c"
                        : "#FF0000"),
            color: isFocused ? '#ffffff' : (data.className.includes('text-wam-white') ? '#ffffff' : '#000000'),
            fontSize: '0.875rem',
            paddingLeft: '0.75rem',
            borderBottom: 'none',
            cursor: 'pointer',
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#000000',
        }),
        multiValue: (provided) => ({
            ...provided,
            borderRadius: '0.375rem',
            backgroundColor: '#d3d3d3',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#000000',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#FF0000',
            ':hover': {
                backgroundColor: '#ffe6e6',
            },
        }),
    };

    return (
        <div className="relative grid grid-cols-12 gap-2 py-1">
            {showLabels && (
                <label
                    htmlFor={configurationItem.classConfiguration}
                    className="block col-span-3 pt-1 text-sm font-medium text-wam-black"
                >
                    {configurationItem.description}
                </label>
            )}
            <div className={`mt-1 sm:mt-0 ${showLabels ? "col-span-9" : "col-span-12"} flex items-center`}>
                {configurationItem.selectionMode === "SingleValue" ? (
                    <div className="relative flex items-center group">
                        <select
                            onChange={handleSingleSelectChange}
                            value={selectedSingleOption || ""}
                            id={configurationItem.classConfiguration}
                            name={configurationItem.classConfiguration}
                            disabled={!configurationItem.enabled}
                            className="block w-full max-w-full cursor-pointer rounded-md border-wam-lightgrey focus:border-wam-darkblue focus:ring-wam-darkblue text-sm text-wam-black py-1 leading-[1.75rem] appearance-none pr-10"
                        >
                            <option value="select">Select</option>
                            {options.map(option => (
                                <option key={option.value} value={option.value} className={option.className}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <div className="absolute inset-y-0 right-0 flex items-center pointer-events-auto group relative">
                            <span className="absolute bottom-full mb-1 hidden group-hover:block bg-wam-black text-wam-white text-xs py-1 px-2 rounded-lg shadow-lg">
                                Info about this selection
                            </span>
                        </div>
                    </div>
                ) : (
                    <div className="relative flex items-center group w-[80%]">
                        <Select
                            isMulti
                            options={options}
                            onChange={handleMultiSelectChange}
                            value={options.filter(option => selectedOptions.includes(option.value)) || []}
                            styles={customStyles}
                            components={{ IndicatorSeparator: () => null }}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                            menuShouldScrollIntoView={false}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pointer-events-auto group relative">
                            <span className="absolute bottom-full mb-1 hidden group-hover:block bg-wam-black text-wam-white text-xs py-1 px-2 rounded-lg shadow-lg">
                                Info about this selection
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
