import React, { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getState } from "../../services/Service";
import ConfigurationModel from "../../features/Configuration/components/ConfigurationModel";
import ConfigurationConflict from "../../features/Configuration/components/ConfigurationConflict";
import { useNavigate } from 'react-router-dom';

const ModelSection = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: stateData, isSuccess } = useQuery("State", getState, {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: false,
    });

    useEffect(() => {
        if (isSuccess && stateData) {
            console.log('Getting selectedConfigModel', stateData.configurationModelId);
            queryClient.setQueryData('selectedConfigurationModel', stateData.configurationModelId);
        }
    }, [stateData, isSuccess, queryClient]);

    const handleModelClick = () => {
        queryClient.setQueryData('selectedConfigurationModel', stateData.configurationModelId);
    };

    return (
        <div className="p-3 min-h-full">
            <h1 className="sr-only">Configuration Models</h1>
            {stateData && stateData.configurationModelId === null && (
                <ConfigurationModel onModelClick={handleModelClick} />
            )}
            {stateData && stateData.conflict.isConflict === true && (
                <ConfigurationConflict />
            )}
        </div>
    );
}

export default ModelSection;
