import axios from "axios";
import _, { values } from "lodash";

axios.defaults.withCredentials = true;

/*Service API Functions*/
let configurationModels = null;

let configurationInstances = null;

let configurationItems = null;

let configurationSummary = [
  {
    id: 1,
    title: "Casing",
    type: "L-Type",
  },
  {
    id: 2,
    title: "Motor",
    type: "867669",
  },
  {
    id: 3,
    title: "Motor Stock",
    type: "20",
  },
];

//API URL
//const APIURL = "https://localhost:7172/api/";
//const APIURL = "/api/"; // "https://localhost:7172/api/"; //"/api/"; //

const APIURL = process.env.REACT_APP_CONFIGURATION_API;

const state = {
  configurationModelId: null,
  configurationInstanceId: "Quotation",
  isLoading: false,
  conflict: {
    isConflict: false,
    key: null,
    value: null,
    selected: null,
  },
};



export function getState() {
  return state;
}

export function setLoading(isLoading) {
  state.isLoading = isLoading;
  return state;
}

export function IsLoading() {
  return state.isLoading;
}

//Configuration Models
export function getConfigurationModels() {
  return axios.get(APIURL + "Configuration/GetModels").then((response) => {
    configurationModels = response.data.$values;
    return configurationModels;
  });

  //if (configurationModels) {
  //  return configurationModels;
  //}
}

export function isSelectedConfigurationModel() {
  return state;
}

export function getSelectedConfigurationModel() {
  var result = null;
  if (configurationModels) {
    configurationModels.filter((cm) => {
      if (cm.configurationModelId === state.configurationModelId) {
        result = cm;
      }
    });
  }
  return result;
}

export function setSelectedConfigurationModel(cms) {
  state.configurationModelId = cms.configurationModelId;
}

//Configuration Instances
export function isSelectedConfigurationInstance() {
  return state;
}

export function setSelectedConfigurationInstance(cms) {
  state.configurationInstanceId = cms.configurationInstanceId;
}

export function getSelectedConfigurationInstance() {
  var result = [];
  if (configurationInstances) {
    configurationInstances.filter((cm) => {
      if (cm.configurationInstanceId === state.configurationInstanceId) {
        result = cm;
      }
    });
  }
  return result;
}

export function getConfigurationInstances() {
    return axios
        .get(APIURL + "Configuration/GetInstances/" + state.configurationModelId)
    .then((response) => {
      configurationInstances = response.data.$values; //_.sortBy(, (o) => o.order);
      return configurationInstances;
    });
}

//Configuration SubGroups
export function getConfigurationSubGroups(citems) {
  const subGroups = _.chain(citems)
    .groupBy((x) => x.subGroup)
    .map((value, key) => ({ subGroup: key, ci: value }))
    .value();

  return subGroups;
}

//Configuration Items
export function getConfigurationItems() {
    if (state.configurationInstanceId == 'ProjectDetails') {
        return axios
            .get(
                APIURL +
                "Configuration/GetConfigurationItems/PROJECT/" +
                state.configurationInstanceId
            )
            .then((response) => {
                configurationItems = response.data.items;
                delete configurationItems.$id;
                delete configurationItems.$type;

                //configurationItems = _.sortBy(configurationItems, (o) => o.order);

                state.isLoading = false;

                return configurationItems;
            });
    }
    else {
        return axios
            .get(
                APIURL +
                "Configuration/GetConfigurationItems/" + state.configurationModelId + "/" +
                state.configurationInstanceId
            )
            .then((response) => {
                configurationItems = response.data.items;
                delete configurationItems.$id;
                delete configurationItems.$type;

                //configurationItems = _.sortBy(configurationItems, (o) => o.order);

                state.isLoading = false;

                return configurationItems;
            });
    }
 
}

//Configuration Values
export function getConfigurationValues(ci) {
  delete ci.values.$id;
  delete ci.values.$type;

  return ci.values;
}

// Add To Project
//export async function addToProject(configData) {
//    try {
//        const response = await axios.post(`${APIURL}/Configuration/AddToProject`, configData);
//        return response.data;
//    } catch (error) {
//        console.error('Error in addToProject service:', error);
//        throw error;
//    }
//}

export async function addToProject(ModelId) {
    return axios
        .get(
            APIURL +
            "Configuration/AddToProject/" + ModelId
        )
        .then((response) => {
            const { State, ...dataWithoutState } = response.data;
            return dataWithoutState;
        })
        .catch((error) => {
            console.error('Error in addToProject service:', error);
            throw error;
        });
}

export function saveProject(username) {
    return axios
        .post(`${APIURL}Configuration/SaveProject`, {
            UserName: username 
        })
        .then((response) => {
            console.log('Project saved successfully:', response.data);
            return true;
        })
        .catch((error) => {
            console.error('Error in saving project:', error);
            throw error;
        });
}

export const duplicateProject = async (projectID) => {
    return axios
        .post(`${APIURL}Configuration/DuplicateProject`, { projectID })
        .then((response) => {
            console.log('Project duplicated successfully:', response.data);
            return response.data; 
        })
        .catch((error) => {
            console.error('Error duplicating project:', error);
            throw error;
        });
};

export const openProject = async (projectID) => {
    return axios
        .post(`${APIURL}Configuration/OpenProject`, { projectID })
        .then((response) => {
            console.log('Project open successfully:', response.data);
            return response.data;
        })
        .catch((error) => {
            console.error('Error opening project:', error);
            throw error;
        });
};
export async function getProjects() {
    try {
        const response = await axios.get(APIURL + "Configuration/getProjects/");

        const responseData = response.data.$values;
        console.log("Response Data:", responseData);

        

        console.log("Final Project List:", responseData);
        return responseData;

    } catch (error) {
        console.error('Error in fetching projects:', error);
        throw error;
    }
}


export async function getProjectByProjectID(ProjectID) {
    return axios
        .get(
            APIURL +
            "Configuration/getProjectByProjectID/" + ProjectID
        )
        .then((response) => {
            console.log("Project data", response.data)
            return response.data;

        })
        .catch((error) => {
            console.error('Error in fetching project:', error);
            throw error;
        });
}

export async function closeProject() {
    try {
        const response = await axios.get(APIURL + "Configuration/CloseProject/");

        return response.data;

    } catch (error) {
        console.error('Error in closing project', error);
        throw error;
    }
}

export async function getProjectItem(ProjectID) {
    return axios
    .get(
        APIURL +
        "Configuration/GetProjectItemsss?ProjectID=" + ProjectID
    )
        .then((response) => {
            console.log("Project data", response.data)
            return response.data;

        })
        .catch((error) => {
            console.error('Error in fetching project:', error);
            throw error;
        });
}

export async function addDuplicatedItem(newItem) {
    try {
        const response = await axios.post(`${APIURL}Configuration/AddDuplicatedItem`, newItem);
        console.log('API response for adding duplicated item:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error adding duplicated item:', error);
        throw error;
    }
}

export async function updateSelectedProjectItem(ModelId, sku) {
 
    try {
        const response = await axios.post(APIURL + "Configuration/UpdateSelectedProjectItems", {
            ModelId: ModelId,
            SKU: sku,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating edited item:', error);
        throw error;
    }
}

export async function getProjectItems() {
    try {
        const response = await axios.get(APIURL + "Configuration/GetProjectItems/");
        console.log('API response:', response.data); 
        if (response.data && response.data.$values) {
            console.log('Extracted Project Items:', response.data.$values); 
            return response.data.$values; 
        } else {
            console.warn('No project items found in response.');
            return []; 
        }
    } catch (error) {
        console.error('Error in Getting Project Items:', error);
        throw error;
    }
}

export const updateProjectStatus = async (projectId, newStatus) => {
    try {
        const response = await axios.post(APIURL + "Configuration/UpdateProjectStatus", {
            ProjectID: projectId,
            Status: newStatus,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating project status:', error);
        throw error;
    }
};

export const deleteProject = async (projectId) => {
    try {
        const response = await axios.post(APIURL + "Configuration/DeleteProject", {
            ProjectID: projectId,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating project status:', error);
        throw error;
    }
};

export async function deleteProjectItemBySKU(sku, index) {
    try {
        const response = await axios.delete(`${APIURL}Configuration/DeleteProjectItemBySKU/${sku}/${index}`);
        console.log(`API response for deletion by SKU:`, response.data);
       
        return response.data;
    } catch (error) {
        console.error('Error deleting project item by SKU:', error);
        throw error;
    }
}


//export async function deleteProjectItemBySKU(sku, index) {
//    try {
//        const response = await axios.delete(`${APIURL}Configuration/DeleteProjectItemBySKU/${sku}/${index}`);
//        console.log(`API response for deletion by SKU:`, response.data);

//        // Call updateConfiguration with empty values after successful deletion
//        await updateConfiguration({
//            key: '',          // Or set any other relevant keys as needed
//            value: '',
//            values: [],
//            selected: [],
//            conflict: null
//        });

//        return response.data;
//    } catch (error) {
//        console.error('Error deleting project item by SKU:', error);
//        throw error;
//    }
//}


//TableColumns
export function getTableColumns() {
  var result = false;
  result =
    _.filter(configurationInstances, function (ci) {
      return ci.instanceType == state.configurationInstanceId;
    }).length > 0;
  return result;
}

//Summary Information
export function isConfigurationSummary() {
  var result = false;
  result =
    _.filter(configurationInstances, function (ci) {
      return ci.instanceType == "Summary";
    }).length > 0;
  return result;
}

export function getConfigurationSummaryItems() {
    return axios
        .get(APIURL + "Configuration/GetConfigurationItems/" + state.configurationModelId + "/Summary")
    .then((response) => {
      configurationSummary = response.data.items;
      delete configurationSummary.$id;
      delete configurationSummary.$type;

      //configurationSummary = _.sortBy(configurationSummary, (o) => o.order);

      state.isLoading = false;

      return configurationSummary;
    });
}

//Update Configuration
export function updateConfiguration(cv) {
    if (state.configurationInstanceId == 'ProjectDetails') {
        return axios
            .post(APIURL + "Configuration/UpdateAvailability", {
                modelId: 'PROJECT',
                instance: state.configurationInstanceId,
                key: cv.key,
                value: cv.value,
                values: cv.values,
                selected: cv.selected,
                conflict: cv.conflict,
            })
            .then((response) => {
                console.log(response);
                configurationItems = response.data.items;
                delete configurationItems.$id;
                delete configurationItems.$type;

                return configurationItems;
            });

    }
    else {
        return axios
            .post(APIURL + "Configuration/UpdateAvailability", {
                modelId: state.configurationModelId,
                instance: state.configurationInstanceId,
                key: cv.key,
                value: cv.value,
                values: cv.values,
                selected: cv.selected,
                conflict: cv.conflict,
            })
            .then((response) => {
                console.log(response);
                configurationItems = response.data.items;
                delete configurationItems.$id;
                delete configurationItems.$type;

                return configurationItems;
            });
    }
  
}
 //documentService.js 
export function generateDocumentService(payload) {
    return axios
        .post(APIURL + "Configuration/GenerateDocument", payload, {
            responseType: 'blob',  
        })
        .then((res) => {
            console.log('Response:', res);
            if (res.status === 200) {
                return res.data;  
            } else {
                throw new Error('Failed to generate document');
            }
        })
        .catch((err) => {
            console.error('Error in generateDocument service:', err);
            throw err;
        });
} 