import { Fragment, useEffect } from "react";
import { Popover, Transition, Menu } from "@headlessui/react";
import {
    Bars3Icon,
    BellIcon,
    XMarkIcon,
    ArrowLeftOnRectangleIcon,
    ChevronRightIcon,
    MagnifyingGlassIcon
} from "@heroicons/react/24/outline";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getSelectedConfigurationModel, setSelectedConfigurationModel } from "../../services/Service";
import { useNavigate, useLocation } from 'react-router-dom';

export function Header() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation(); 

    const { data: selectedConfigModel } = useQuery("SelectedConfigurationModel", getSelectedConfigurationModel, {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: true,
        retry: false,
    });

    const changeState = useMutation(setSelectedConfigurationModel, {
        onSuccess: () => {
            queryClient.invalidateQueries("SelectedConfigurationModel");
            navigate('/models');
        },
    });

    const user = { name: "Test", email: "test@example.com", imageUrl: "", };
    const userNavigation = null;
    const path = "../assets/images/configurationModel/";

    const handleLogout = () => {
        navigate('/login');
        window.location.reload();
    };

    //useEffect(() => {
    //    const currentPath = location.pathname;
    //    console.log(`Current Path: ${currentPath}`);
    //    console.log(`Selected Config Model: ${selectedConfigModel}`);

    //    const pathsWhereModelIsSet = ['/items'];

    //    if (!pathsWhereModelIsSet.includes(currentPath)) {
    //        if (selectedConfigModel !== null) {
    //            console.log('Setting selectedConfigModel to null');
    //            changeState.mutate({ configurationModelId: null });
    //        }
    //    }
    //}, [location.pathname, selectedConfigModel, changeState]);

    return (
        <Popover as="header" className="bg-wam-white">
            {({ open }) => (
                <>
                    <div>
                        <div className="flex items-center justify-center p-2 lg:justify-between">
                            {/* Logo */}
                            <div className="flex flex-row">
                                <div>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            changeState.mutate({ configurationModelId: null });
                                        }}
                                    >
                                        <span className="sr-only">Trilogy</span>
                                    </a>
                                </div>

                                <h2 className="my-auto text-wam-black text-2xl pl-2 font-bold">
                                    Configurator
                                </h2>

                                {process.env.REACT_APP_ALPHA_MODE === "true" && (
                                    <h2 className="my-auto text-wam-red text-2xl pl-2 font-bold">
                                        Test environment
                                    </h2>
                                )}
                            </div>

                            {/* Right section on desktop */}
                            <div className="hidden absolute right-0 flex-shrink-0 lg:block">
                                <button
                                    type="button"
                                    className="flex-shrink-0 rounded-full p-1 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                >
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                <button
                                    onClick={handleLogout}
                                    className="flex-shrink-0 rounded-full p-1 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                    <ArrowLeftOnRectangleIcon className="h-6 w-6" aria-hidden="true" />
                                    <span className="sr-only">Logout</span>
                                </button>

                                {/* Profile dropdown */}
                                {userNavigation && (
                                    <Menu as="div" className="relative ml-4 flex-shrink-0">
                                        <div>
                                            <Menu.Button className="flex rounded-full bg-white text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                                                <span className="sr-only">Open user menu</span>
                                                <img
                                                    className="h-8 w-8 rounded-full"
                                                    src={user.imageUrl}
                                                    alt=""
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute -right-2 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"></Menu.Items>
                                        </Transition>
                                    </Menu>
                                )}
                            </div>

                            {/* Menu button */}
                            <div className="absolute right-0 flex-shrink-0 lg:hidden">
                                {/* Mobile menu button */}
                                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Popover.Button>
                            </div>
                        </div>
                        <div className="hidden border-opacity-20 p-1 lg:block bg-wam-lightgrey">
                            <div className="grid grid-cols-3 content-end">
                                <div className="col-span-2">
                                    <nav className="flex" aria-label="Breadcrumb">
                                        {selectedConfigModel && (
                                            <ol role="list" className="flex items-center space-x-2">
                                                <li>
                                                    <div>
                                                        <a
                                                            href="#"
                                                            className="text-wam-black text-sm font-medium"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                changeState.mutate({ configurationModelId: null });
                                                            }}
                                                        >
                                                            <h2>Models</h2>
                                                        </a>
                                                    </div>
                                                </li>

                                                <li key={"Breadcrumb"}>
                                                    <div className="flex items-center">
                                                        <ChevronRightIcon
                                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                        <a
                                                            key={selectedConfigModel.configurationModelId}
                                                            className="flex min-w-0 flex-1 items-center text-wam-black text-sm font-medium rounded-md px-1"
                                                        >
                                                            <img
                                                                className="h-10 w-10 rounded-full"
                                                                src={`${path}${selectedConfigModel.iconPath}`}
                                                                alt={selectedConfigModel.description}
                                                            />
                                                            <h2 className="px-2">
                                                                {selectedConfigModel.description}
                                                            </h2>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ol>
                                        )}
                                    </nav>
                                </div>
                                <div className="grid grid-cols-3">
                                    <div className="col-span-2"></div>
                                    <div>
                                        <label htmlFor="mobile-search" className="sr-only">
                                            Search
                                        </label>
                                        <div className="relative text-white focus-within:text-gray-600">
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                <MagnifyingGlassIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <input
                                                id="mobile-search"
                                                className="block w-full rounded-md border border-transparent bg-white bg-opacity-20 py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-white focus:border-transparent focus:bg-opacity-100 focus:placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
                                                placeholder="Search"
                                                type="search"
                                                name="search"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Transition.Root as={Fragment}>
                        <div className="lg:hidden">
                            <Transition.Child
                                as={Fragment}
                                enter="duration-150 ease-out"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="duration-150 ease-in"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Popover.Overlay className="fixed inset-0 z-20 bg-wam-black bg-opacity-25" />
                            </Transition.Child>

                            <Transition.Child
                                as={Fragment}
                                enter="duration-150 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="duration-150 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel
                                    focus
                                    className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-1 transition"
                                >
                                    <div className="divide-y divide-gray-200 bg-wam-white shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="px-4 pt-5 pb-6 sm:px-6">
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                                                        Configuration
                                                    </h2>
                                                </div>
                                                <div className="ml-3">
                                                    <button
                                                        type="button"
                                                        className="inline-flex items-center justify-center rounded-md p-1 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                    >
                                                        <span className="sr-only">Close menu</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <nav className="space-y-1">
                                                    <a
                                                        href="#"
                                                        onClick={() => {
                                                            changeState.mutate({ configurationModelId: null });
                                                        }}
                                                        className="group flex items-center rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                                                    >
                                                        <h2>Models</h2>
                                                    </a>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="space-y-1 p-4">
                                            <button
                                                onClick={handleLogout}
                                                className="group flex w-full items-center rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                                            >
                                                <ArrowLeftOnRectangleIcon className="h-6 w-6" aria-hidden="true" />
                                                <h2 className="ml-3">Logout</h2>
                                            </button>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition.Child>
                        </div>
                    </Transition.Root>
                </>
            )}
        </Popover>
    );
}
