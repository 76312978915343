import React from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getConfigurationModels, setSelectedConfigurationModel } from "../../../services/Service";
import { useNavigate } from 'react-router-dom';

export default function ConfigurationModel({ onModelClick }) {
    const queryClient = useQueryClient();
    const navigate = useNavigate(); // Hook for navigation
    const path = "../assets/images/configurationModel/";

    // Queries
    const { data: modelsData, refetch } = useQuery(
        "ConfigurationModels",
        () => getConfigurationModels(),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true, 
            refetchOnReconnect: true,
            retry: false,
        }
    );

    // Mutations
    const mutation = useMutation(setSelectedConfigurationModel, {
        onSuccess: () => {
            queryClient.invalidateQueries("ConfigurationModels");
            queryClient.invalidateQueries("SelectedConfigurationModel");
            queryClient.invalidateQueries("State");
            
            if (onModelClick) {
                onModelClick();
            }
            navigate('/items', { replace: true }); 
        },
    });

    return (
        <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6"
        >
            {modelsData &&
                modelsData
                .filter(cm => cm.configurationModelId !== "PROJECT")
                .map((cm) => (
                    <li
                        key={cm.configurationModelId}
                        className={
                            cm.enabled
                                ? "col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                                : "col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow opacity-30"
                        }
                        onClick={
                            cm.enabled
                                ? () => {
                                    mutation.mutate({
                                        configurationModelId: cm.configurationModelId,
                                    });
                                }
                                : null
                        }
                    >
                        <div className="flex flex-1 flex-col p-8">
                            <img
                                className="mx-auto h-20 w-56 flex-shrink-0"
                                src={path + `${cm.iconPath}`}
                                alt=""
                            />
                            <h3 className="mt-6 text-sm font-medium text-gray-900">
                                {cm.description}
                            </h3>
                        </div>
                    </li>
                ))}
        </ul>
    );
}
