import { getConfigurationValues, getState } from "../../../services/Service";
import { useQuery, useQueryClient } from "react-query";
import React, { useState } from "react";
import useFetchOptions from "../hooks/useFetchOptions";

export default function ConfigurationImage({ configurationItem, mutation, onSelectMultiOptions }) {
    const queryClient = useQueryClient();
    const configurationValues = getConfigurationValues(configurationItem);
    const [selectedSingle, setSelectedSingle] = useState(null);
    const [selectedMulti, setSelectedMulti] = useState([]);
    const options = useFetchOptions(configurationItem);
    const { data: stateData } = useQuery("State", getState, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    });
    const handleSingleSelect = (id) => {
        setSelectedSingle(id);
        checkConflict(configurationItem.classConfiguration, id, true);
    };


    const handleMultiSelect = (id) => {
        setSelectedMulti((prev) => {
            const isSelected = prev.includes(id);
            const updatedSelection = isSelected
                ? prev.filter((item) => item !== id)
                : [...prev, id];
            onSelectMultiOptions(updatedSelection);
            checkConflict(configurationItem.classConfiguration, updatedSelection, true);
            return updatedSelection;
        });
    };

    const checkConflict = (key, values, selected) => {
        if (!Array.isArray(values)) {
            const isAvailable = configurationValues[values]?.available;
            if (!isAvailable) {
                queryClient.setQueryData("State", (oldState) => ({
                    ...oldState,
                    conflict: { isConflict: true, key, value: values, selected },
                }));
            } else {
                mutation.mutate({ key, value: values, selected });
            }
        } else {
            const conflicts = values.map(value => ({
                value,
                isAvailable: configurationValues[value]?.available,
            }));

            const hasConflict = conflicts.some(conflict => !conflict.isAvailable);
            if (hasConflict) {
                queryClient.setQueryData("State", (oldState) => ({
                    ...oldState,
                    conflict: {
                        isConflict: true,
                        key,
                        values,
                        selected,
                    },
                }));
            } else {
                mutation.mutate({ key, values, selected });
            }
        }
    };

    const isSelectedSingle = (id) => selectedSingle === id;
    const isSelectedMulti = (id) => selectedMulti.includes(id);

    return (
        <div
            key={"IMAGE" + configurationItem.classConfiguration}
            className="grid grid-cols-6 gap-5 pt-2"
        >
            {configurationItem.selectionMode === "SingleValue" ? (
                // Single Select Row
                <div className="col-span-6 grid grid-cols-3 gap-4">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => handleSingleSelect(option.id)}
                            className={`flex flex-col items-center p-2 border rounded-lg bg-wam-white cursor-pointer ${isSelectedSingle(option.id)
                                ? "border-wam-red bg-wam-lightgrey"
                                : ""
                                }`}
                        >
                            <img
                                src={option.imgSrc}
                                alt={option.label}
                                className="w-20 h-20 rounded-lg"
                            />
                            <label className="mt-2 text-sm font-medium text-wam-black">
                                {option.label}
                                <div className="relative flex items-center group ml-2">
                                    <i className="fas fa-info-circle text-wam-black text-sm"></i>
                                    <span className="absolute bottom-full mb-1 hidden group-hover:block bg-wam-black text-wam-white text-xs py-1 px-2 rounded-lg shadow-lg">
                                        {option.label}
                                    </span>
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            ) : (
                // Multi Select Row
                    <div className="col-span-6 grid grid-cols-3 gap-4 mt-4">
                        {options.map((option) => (
                            <div
                                key={option.id}
                                onClick={() => handleMultiSelect(option.id)}
                                className={`flex flex-col items-center p-2 border rounded-lg bg-wam-white cursor-pointer ${isSelectedMulti(option.id)
                                    ? "border-wam-red bg-wam-lightgrey"
                                    : ""
                                    }`}
                            >
                                <img
                                    src={option.imgSrc}
                                    alt={option.label}
                                    className="w-20 h-20 rounded-lg"
                                />
                                <label className="mt-2 text-sm font-medium text-wam-black">
                                    {option.label}
                                    <div className="relative flex items-center group ml-2">
                                        <i className="fas fa-info-circle text-wam-black text-sm"></i>
                                        <span className="absolute bottom-full mb-1 hidden group-hover:block bg-wam-black text-wam-white text-xs py-1 px-2 rounded-lg shadow-lg">
                                            {option.label}
                                        </span>
                                    </div>
                                </label>
                            </div>
                        ))}
                    </div>
            )}
        </div>
    );
}
