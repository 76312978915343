import { getConfigurationValues, getState, updateConfiguration } from "../../../services/Service";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { GlobalStateContext } from "../../../context/GlobalStateContext";
import React, { useState, useEffect, useContext } from "react";
import useFetchOptions from "../hooks/useFetchOptions";


/*configurationItem,
    mutation,
    showLabels = true,
    selectedSingleOption = '',  // Default to empty string
    selectedOptions = [],  // Default to empty array
    onSingleSelectChange,
    onMultiSelectChange*/
export default function ConfigurationButton({
    configurationItem,
    mutation1,
    onSelectMultiOptions,
    onSingleSelectChange = null,
    selectedMulti = [],
    selectedSingle = null
}) {
    const queryClient = useQueryClient();
    const [selectedSingleState, setSelectedSingleState] = useState(selectedSingle);
    const [selectedMultiState, setSelectedMultiState] = useState(selectedMulti);
    const options = useFetchOptions(configurationItem);
    const { globalState, updateGlobalState, resetTextBoxValue } = useContext(GlobalStateContext);
    const configurationValues = getConfigurationValues(configurationItem);

    const filteredOptions = options.filter((option) => configurationValues[option.id]?.available);

    const { data: stateData } = useQuery("State", getState, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    });
    const mutation = useMutation({
        mutationFn: updateConfiguration,
        onMutate: () => {
            //setLoading(true);
            queryClient.invalidateQueries("State");
        },
        onSuccess: (data) => {
            queryClient.setQueryData("ConfigurationItems", data);
            queryClient.invalidateQueries("ConfigurationSummaryItems");
            // setLoading(false);
            queryClient.invalidateQueries("State");
        },
    });

    const handleSingleSelect = (id) => {
        setSelectedSingleState(id);
        onSingleSelectChange(configurationItem.classConfiguration,id);
        checkConflict(configurationItem.classConfiguration, id, true);
        updateGlobalState({
            selectedOptions: {
                ...globalState.selectedOptions,
                [configurationItem.classConfiguration]: id,
            },
        });

    };

    const handleMultiSelect = (id) => {
        setSelectedMultiState((prev) => {
            const isSelected = prev.includes(id);
            const updatedSelection = isSelected
                ? prev.filter((item) => item !== id)
                : [...prev, id];

            onSelectMultiOptions(updatedSelection);
            checkConflict(configurationItem.classConfiguration, updatedSelection, true); 

            updateGlobalState((prevState) => ({
                ...prevState,
                selectedMultiSelectOptions: {
                    ...prevState.selectedMultiSelectOptions, 
                    [configurationItem.classConfiguration]: updatedSelection, 
                },
            }));
            return updatedSelection;
        });
    };


    const checkConflict = (key, values, selected) => {
        if (!Array.isArray(values)) {
            const isAvailable = configurationValues[values]?.available;
            if (!isAvailable) {
                queryClient.setQueryData("State", (oldState) => ({
                    ...oldState,
                    conflict: { isConflict: true, key, value: values, selected },
                }));
            } else {
                mutation.mutate({ key, value: values, selected });
            }
        } else {
            const conflicts = values.map(value => ({
                value,
                isAvailable: configurationValues[value]?.available,
            }));

            const hasConflict = conflicts.some(conflict => !conflict.isAvailable);
            if (hasConflict) {
                queryClient.setQueryData("State", (oldState) => ({
                    ...oldState,
                    conflict: {
                        isConflict: true,
                        key,
                        values,
                        selected,
                    },
                }));
            } else {
                mutation.mutate({ key, values, selected });
            }
        }
    };

    const isSelectedSingle = (id) => selectedSingle === id;
    const isSelectedMulti = (id) => selectedMultiState.includes(id);

    return (
        <div
            key={"BUTTON" + configurationItem.classConfiguration}
            className="relative grid grid-cols-6 pt-2"
        >
            {configurationItem.selectionMode === "SingleValue" ? (
                // Single Select Row
                <div className="col-span-6 grid grid-cols-3 gap-4">
                    {filteredOptions.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => handleSingleSelect(option.id)}
                            className={`flex flex-col p-1 border rounded-lg bg-wam-white cursor-pointer relative group ${isSelectedSingle(option.id) ? "border-wam-red bg-wam-black" : ""}`}
                        >
                            <label className="text-sm font-medium text-wam-black flex items-center">
                                {option.label}
                            </label>
                            <span className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-black text-wam-white text-xs py-1 px-2 rounded-lg shadow-lg whitespace-nowrap">
                                {option.label}
                            </span>
                        </div>
                    ))}
                </div>
            ) : (
                // Multi Select Row
                <div className="col-span-6 grid grid-cols-3 gap-4 mt-4">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => handleMultiSelect(option.id)}
                            className={`flex flex-col p-2 border rounded-lg bg-wam-white cursor-pointer relative group ${isSelectedMulti(option.id) ? "border-wam-red bg-wam-lightgrey" : ""}`}
                        >
                            <label className="mt-2 text-sm font-medium text-wam-black flex items-center">
                                {option.label}
                            </label>
                            <span className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-black text-wam-white text-xs py-1 px-2 rounded-lg shadow-lg whitespace-nowrap">
                                {option.label}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
