import { Switch } from "@headlessui/react";
import { getConfigurationValues } from "../../../services/Service";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ({ configurationItem, mutation }) {
  const configurationValues = getConfigurationValues(configurationItem);

  return (
    <div
      key={"PARENTSWITCH" + configurationItem.classConfiguration}
      className="grid grid-cols-11  items-start gap-2"
    >
      <label
        key={"LABELSWITCH" + configurationItem.classConfiguration}
        htmlFor={configurationItem.classConfiguration}
        className="block col-span-4 text-sm font-medium text-wam-black mt-px pt-2"
      >
        {configurationItem.description}
      </label>

      <div className="flex pt-1 col-span-3">
        {Object.keys(configurationValues)
          .sort(function (a, b) {
            return a.sortOrder - b.sortOrder;
          })
          .map((cvkey) => {
            const configurationValue = configurationValues[cvkey];

            configurationValue.selected = configurationValue.selected ?? false;

            return (
              <Switch
                key={"SWITCH" + configurationItem.classConfiguration}
                checked={configurationValue.selected}
                disabled={!configurationItem.enabled}
                onChange={(event) => {
                  mutation.mutate({
                    key: configurationItem.classConfiguration,
                    value: configurationValue.value,
                    selected: !configurationValue.selected,
                  });
                }}
                className={classNames(
                  configurationValue.selected
                    ? "bg-wam-darkblue"
                    : "bg-wam-black",
                  "mt-1 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">
                  {configurationValue.description}
                </span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    configurationValue.selected
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-wam-white ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            );
          })}
        <div className="block text-sm font-medium text-wam-black pt-2 px-2">
          {configurationItem.locked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 cursor-pointer"
            >
              <path
                fillRule="evenodd"
                d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {!configurationItem.locked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 cursor-pointer"
            >
              <path d="M18 1.5c2.9 0 5.25 2.35 5.25 5.25v3.75a.75.75 0 01-1.5 0V6.75a3.75 3.75 0 10-7.5 0v3a3 3 0 013 3v6.75a3 3 0 01-3 3H3.75a3 3 0 01-3-3v-6.75a3 3 0 013-3h9v-3c0-2.9 2.35-5.25 5.25-5.25z" />
            </svg>
          )}
        </div>

        <div className="block text-sm font-medium text-wam-black mt-px pt-2 pr-2">
          {configurationItem.isDefined && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5 cursor-pointer"
              onClick={(event) => {
                mutation.mutate({
                  key: configurationItem.classConfiguration,
                  value: configurationItem.computedSelectedValueOrDefault,
                  selected: false,
                });
              }}
            >
              <path
                fillRule="evenodd"
                d="M9.53 2.47a.75.75 0 010 1.06L4.81 8.25H15a6.75 6.75 0 010 13.5h-3a.75.75 0 010-1.5h3a5.25 5.25 0 100-10.5H4.81l4.72 4.72a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}
