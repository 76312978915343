import React from "react";

const ProjectCloseModal = ({ isOpen, onClose, onSaveAndClose, onCloseModel }) => {
    if (!isOpen) return null; 
    return (
        <div className="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="modal-content bg-wam-lightgrey p-6 rounded shadow-lg">
                <h2 className="text-lg font-bold mb-4">Close Project</h2>
                <p>Are you sure you want to close the project? Any unsaved changes will be lost.</p>
                <div className="flex space-x-4 mt-6">
                    <button
                        className="bg-wam-lightgrey text-white px-4 py-2 rounded shadow hover:bg-wam-green"
                        onClick={onSaveAndClose}
                    >
                        Yes and Close
                    </button>
                    <button
                        className="bg-wam-lightgrey text-white px-4 py-2 rounded shadow hover:bg-wam-green"
                        onClick={onClose}
                    >
                        Yes
                    </button>
                    <button
                        className="bg-wam-lightgrey text-white px-4 py-2 rounded shadow hover:bg-wam-red"
                        onClick={onCloseModel}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProjectCloseModal;
