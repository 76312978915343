 export default function ({ configurationItem, showLables }) {
   return (
     <div
       key={"TEXT" + configurationItem.classConfiguration}
       className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-5 sm:py-1"
     >
       {showLables && (
         <label
           key={"KEY" + configurationItem.classConfiguration}
           htmlFor={"INPUT" + configurationItem.classConfiguration}
           className="block sm:col-span-2 text-sm font-medium text-wam-black"
         >
           {configurationItem.description}
         </label>
       )}
       <div
         key={"INPUTPARENT" + configurationItem.classConfiguration}
         className={
           "mt-1 sm:mt-0" + (showLables ? " sm:col-span-3" : " sm:col-span-5")
         }
       >
         <input
           disabled
           key={"INPUT" + configurationItem.classConfiguration}
           type="text"
           value={configurationItem.computedValue}
           name={"INPUT" + configurationItem.classConfiguration}
           id={"INPUT" + configurationItem.classConfiguration}
           className="block w-full rounded-md  border-wam-lightgrey focus:border-wam-darkblue focus:ring-wam-darkblue text-sm text-wam-black py-1"
         />
       </div>
     </div>
   );
}

//import React from "react";

//const textClass = "grid grid-cols-5 items-start gap-5 py-1";
//const labelClass = "block col-span-2 text-sm font-medium text-wam-black pt-1";
//const inputParentClass = "mt-1 mt-0";
//const inputClass =
//  "block w-full rounded-md  border-wam-lightgrey focus:border-wam-darkblue focus:ring-wam-darkblue text-sm text-wam-black py-1 w-3/4 mx-auto lg:w-full";

//export default React.memo(function TextInput({
//  configurationItem,
//  showLables,
//}) {
//  return (
//    <div className={textClass}>
//      {showLables && (
//        <label
//          htmlFor={configurationItem.classConfiguration}
//          className={labelClass}
//        >
//          {configurationItem.description}
//        </label>
//      )}
//      <div
//        className={
//          inputParentClass + (showLables ? " col-span-3" : " col-span-5")
//        }
//      >
//        <input
//          disabled
//          type="text"
//          value={configurationItem.computedValue}
//          name={configurationItem.classConfiguration}
//          id={configurationItem.classConfiguration}
//          className={inputClass}
//        />
//      </div>
//    </div>
//  );
//});
