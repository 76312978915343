import { useState, useEffect } from "react";
import { getConfigurationValues } from "../../../services/Service";

const useFetchOptions = (configurationItem) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const values = getConfigurationValues(configurationItem);
                
                if (values && typeof values === 'object') {
                    const optionList = Object.keys(values).map((key) => ({
                        id: key,
                        label: values[key]?.description || 'No description',
                        imgSrc: values[key]?.image || '',
                    }));
                    setOptions(optionList);
                } else {
                    console.warn('No valid values found for configurationItem:', configurationItem);
                    setOptions([]);
                }
            } catch (error) {
                console.error('Error fetching options:', error);
                setOptions([]);
            }
        };

        fetchOptions();
    }, [configurationItem]);

    return options;
};

export default useFetchOptions;
