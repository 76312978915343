import React, { useState } from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import { useTranslate } from "@tolgee/react";
 

const PasswordResetRequestForm = ({ PasswordResetRequestForm }) => {
    const [email, setEmail] = useState(''); 
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

         
    };
    const { t } = useTranslate();

    return (
        <div className="flex items-center justify-center min--screen bg-wam-gray">
            <div className="w-full max-w-md p-8  shadow-lg rounded-lg bg-wam-lightgrey">
                <h2 className="text-2xl font-bold mb-6">{t('Reset-Password', { ns: 'account' })}</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('Email-Address', { ns: 'account' })}</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div> 
                    <button
                        type="submit"
                        className="bg-wam-orange w-full px-4 py-2 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        {t('Reset-Password', { ns: 'account' })}
                    </button>
                </form> 
            </div>
        </div>

    );
};

export default PasswordResetRequestForm;   