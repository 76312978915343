import React from "react";

const footerNavigation = {
    follow: [],
    shortcuts: [
        { name: "Company", href: "/en-gb/company/" },
        { name: "Products", href: "/en-gb/products/" },
        { name: "Applications", href: "/en-gb/applications/" },
        { name: "Resources", href: "/en-gb/resources/" },
        { name: "News", href: "/en-gb/news/" },
        { name: "Contact Us", href: "/en-gb/contact-us/" },
    ],
    contact: [
        { name: "Find a sales contact", href: "/en-gb/contact-us/contact-us/" },
        { name: "Our Offices", href: "/en-gb/contact-us/contact-us/" },
        { name: "Telephone", href: "tel:1234" },
        { name: "email@address.com", href: "mailto:email@address.com" },
    ],
    links: [
        { name: "Imprint", href: "/en-gb/legal/imprint/" },
        { name: "Privacy Policy", href: "/en-gb/legal/privacy-policy/" },
        { name: "Terms of Use", href: "/en-gb/legal/terms-of-use/" },
        { name: "Legal Notice", href: "/en-gb/legal/legal-notice/" },
    ],
};

export function Footer() {
    return (
        <footer className="bg-wam-darkergrey" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="grid grid-cols-1 gap-12 sm:grid-cols-3 sm:gap-8">
                    <div>
                        <h3 className="text-base font-si text-wam-white text-2xl border-b pb-2">
                            FOLLOW US
                        </h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {footerNavigation.follow.map((item) => (
                                <li key={item.name}>
                                    <a href={item.href} className="text-base text-wam-white">
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-base font-si text-wam-white text-2xl border-b pb-2">
                            SHORTCUTS
                        </h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {footerNavigation.shortcuts.map((item) => (
                                <li key={item.name}>
                                    <a href={item.href} className="text-base text-wam-white">
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <h3 className="text-base font-si text-wam-white text-2xl border-b pb-2">
                            CONTACT US
                        </h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {footerNavigation.contact.map((item) => (
                                <li key={item.name}>
                                    <a href={item.href} className="text-base text-wam-white">
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="mt-8 pt-8 flex flex-col items-center md:flex-row md:justify-between">
                    <div className="flex space-x-6">
                        {footerNavigation.links.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-base text-wam-mediumgrey hover:text-wam-white"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <p className="mt-8 text-base text-wam-mediumgrey md:mt-0">
                        &copy; Greybeard Trilogy {new Date().getFullYear()}
                    </p>
                </div>
            </div>
        </footer>
    );
}
