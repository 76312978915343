import React from 'react';

const ConfirmModal = ({ isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null; 

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-start pt-10">
            <div className="bg-wam-lightgrey p-6 rounded shadow-lg w-full max-w-md">
                <h2 className="text-lg font-bold mb-4">Are you sure you want to save the project?</h2>
                <div className="flex justify-end space-x-4">
                    <button
                        className="bg-wam-gray text-black px-4 py-2 rounded hover:bg-wam-red"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-wam-green text-white px-4 py-2 rounded shadow"
                        onClick={onConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
