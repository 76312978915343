
import { useQuery } from "react-query";
import { useEffect } from "react";
import { getConfigurationSummaryItems, getSelectedConfigurationInstance } from "../../../services/Service";
import { find } from "lodash";

export default function ConfigurationSummary() {
    // Queries
    const { data: summaryItems, isLoading: isSummaryLoading } = useQuery(
        "ConfigurationSummaryItems",
        getConfigurationSummaryItems,
        {
            refetchOnWindowFocus: false,
        }
    );

    const { data: selectedInstance, isLoading: isSelectedInstanceLoading } = useQuery(
        "SelectedConfigurationInstance",
        getSelectedConfigurationInstance,
        {
            refetchOnWindowFocus: false,
        }
    );

    const extractInstanceFromExpression = (expression, regex) => {
        const match = expression.match(regex);
        return match ? match[1] : null;
    };

    const groupItemsByInstanceAndSubGroup = (items, instance) => {
        const filteredKeys = Object.keys(items)
            .filter(key => {
                const item = items[key];
                return item.description === item.subGroup && isValidValue(item.computedValue);
            })
            .map(key => items[key].subGroup);
        if (instance.configurationInstanceId === "Summary") {
            const grouped = {};
            Object.keys(items).forEach((key) => {
                const item = items[key];
                const subGroupKey = item.subGroup || "UnknownSubGroup";
                if (filteredKeys.includes(subGroupKey)) {
                    if (!grouped[subGroupKey]) {
                        grouped[subGroupKey] = [];
                    }
                    grouped[subGroupKey].push({ key, ...item });
                }
            });
            return grouped;
        }
        if (!instance) return {};

        const grouped = {};

        Object.keys(items).forEach((key) => {
            const item = items[key];

            const regex1 = /Config\["([^"]+)"\,"[^"]+"\]\.(.*)/;
            const regex2 = /Greybeard\.Join\(Config\["([^"]+)"\,"[^"]+"\]\.SelectedItems\)/;
            const expressionInstance1 = extractInstanceFromExpression(item.onValueExpression, regex1);
            const expressionInstance2 = extractInstanceFromExpression(item.onValueExpression, regex2);

            if (
                expressionInstance1 === instance.configurationInstanceId ||
                expressionInstance2 === instance.configurationInstanceId
            ) {
                const subGroupKey = item.subGroup || "UnknownSubGroup";
                if (filteredKeys.includes(subGroupKey)) {
                    if (!grouped[subGroupKey]) {
                        grouped[subGroupKey] = [];
                    }
                    grouped[subGroupKey].push({ key, ...item });
                }
            }
        });


        return grouped;
    };

    const isValidValue = (value) => value !== null && value !== "-" && value.trim() !== "";

    const groupedItems = summaryItems ? groupItemsByInstanceAndSubGroup(summaryItems, selectedInstance) : {};

    const shouldShowAllSummary = !isSelectedInstanceLoading && !selectedInstance;



    return (
        <>
            {isSummaryLoading || isSelectedInstanceLoading ? (
                <div className="flex h-full justify-start items-center">
                    <svg
                        className="w-12 h-12 animate-spin text-indigo-400"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 4.75V6.25"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M17.1266 6.87347L16.0659 7.93413"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M19.25 12L17.75 12"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M17.1266 17.1265L16.0659 16.0659"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12 17.75V19.25"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M7.9342 16.0659L6.87354 17.1265"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M6.25 12L4.75 12"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M7.9342 7.93413L6.87354 6.87347"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            ) : (
                <div className="overflow-hidden rounded-md h-full">
                    {shouldShowAllSummary ? (
                        //Object.entries(groupItemsByInstanceAndSubGroup(summaryItems, { configurationInstanceId: "Summary" })).map(([subGroupKey, items]) => {
                        Object.entries(groupItemsByInstanceAndSubGroup(summaryItems, { configurationInstanceId: "Summary" })).map(([subGroupKey, items]) => {
                            const validItems = items.filter(({ computedValue }) => isValidValue(computedValue));

                            return validItems.length > 0 ? (
                                <div key={subGroupKey} className="p-4 bg-wam-lightgrey border border-gray-200 rounded-md my-2 shadow-sm">
                                    <h3 className="font-bold text-lg mb-2">{subGroupKey}</h3>
                                    {validItems.map(({ key, description, computedValue }) => (
                                        <div
                                            key={key}
                                            className="p-2 grid grid-cols-4 gap-4 py-1 bg-wam-lightgrey border border-wam-black rounded-md my-1"
                                        >
                                            <dt className="text-sm font-medium col-span-2 text-wam-black">
                                                {description}
                                            </dt>
                                            <dd className="mt-1 text-sm col-span-2 text-wam-black">
                                                {Array.isArray(computedValue)
                                                    ? computedValue.join(", ")
                                                    : computedValue}
                                            </dd>
                                        </div>
                                    ))}
                                </div>
                            ) : null;
                        })
                    ) : (
                        <div className="p-4">
                            {Object.entries(groupedItems).length > 0 && (
                                <>
                                    <div className="p-4 bg-wam-lightgrey border border-gray-200 rounded-md my-2 shadow-sm">
                                        <h2 className="font-bold text-2xl mb-4">{selectedInstance.configurationInstanceDescription}</h2>
                                        {Object.entries(groupedItems).map(([subGroupKey, items]) => {
                                            if (!subGroupKey) return null;

                                            //const validItems = items.filter(({ computedValue }) => isValidValue(computedValue));
                                            const validItems = items.filter(({ computedValue, visible }) => isValidValue(computedValue) && visible);
                                            return validItems.length > 0 ? (
                                                <div key={subGroupKey} className="">
                                                    {/* <h3 className="font-semibold text-md mb-2">{subGroupKey}</h3>*/}
                                                    {validItems.map(({ key, description, computedValue }) => (
                                                        //<div
                                                        //    key={key}
                                                        //    className="p-2 grid grid-cols-4 gap-4 py-1 bg-wam-lightgrey border border-wam-black rounded-md my-1"
                                                        //>
                                                        //    <dt className="text-sm font-medium col-span-2 text-wam-black">
                                                        //        {description}
                                                        //    </dt>
                                                        //    <dd className="mt-1 text-sm col-span-2 text-wam-black">
                                                        //        {Array.isArray(computedValue)
                                                        //            ? computedValue.join(", ")
                                                        //            : computedValue}
                                                        //    </dd>
                                                        //</div>
                                                        <div
                                                            key={key}
                                                            className="p-2 grid grid-cols-2 gap-4 items-center bg-wam-lightgrey border border-wam-black rounded-md my-1"
                                                        >
                                                            <dt className="text-sm font-medium text-wam-black">
                                                                {description}
                                                            </dt>
                                                            <dd className="text-sm text-wam-black">
                                                                {Array.isArray(computedValue) ? computedValue.join(", ") : computedValue}
                                                            </dd>
                                                        </div>

                                                    ))}
                                                </div>
                                            ) : null;
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
