import React from 'react';

const StatusModal = ({ isOpen, status, message, onClose }) => {
    if (!isOpen) return null;

    const modalBg = status === 'success' ? 'bg-green-500' : 'bg-red-500';

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-wam-lightgrey rounded-lg shadow-lg w-1/3 p-4">
                <div className="p-4">
                    <p>{message}</p>
                </div>
                <div className="flex justify-end space-x-2 p-4">
                    <button
                        className="bg-wam-lightgrey text-white px-4 py-2 rounded shadow hover:bg-wam-red"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StatusModal;
