import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { b2cPolicies } from './features/Account/components/authConfig';
import  Layout  from '../src/components/Layout';
import { Account } from './features/Account/components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ErrorBoundary from './utils/errors/ErrorBoundary';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Tolgee, DevTools, TolgeeProvider, FormatSimple } from "@tolgee/react";
import Signup from './features/Account/components/SignUp';
import { GlobalStateProvider } from './/context/GlobalStateContext';

const queryClient = new QueryClient();

const AppContent = () => {
    const { instance, inProgress } = useMsal();
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [tolgee, setTolgee] = useState(null);

    // Initialize Tolgee for translations
    useEffect(() => {
        const initializeTolgee = async () => {
            try {
                const tolgeeInstance = Tolgee()
                    .use(DevTools())
                    .use(FormatSimple())
                    .init({
                        language: 'en',
                        apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
                        apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
                        defaultNs: 'common',
                        ns: ['common', 'account'],
                    });
                setTolgee(tolgeeInstance);
            } catch (error) {
                console.error('Error initializing Tolgee:', error);
            }
        };

        initializeTolgee();
    }, []);

    useEffect(() => {
        if (inProgress === 'none') {
            const account = instance.getActiveAccount();
            if (!account) {
                // No account active, redirect to login
                instance.loginRedirect();
            } else {
                // Account is active, user is authenticated
                setIsAuthenticated(true);
            }
        }
    }, [instance, inProgress]);

    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => { 
            if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload.account) {
                const idTokenClaims = event.payload.idTokenClaims; 
                const isForgotPassword = idTokenClaims?.tfp === b2cPolicies.names.forgotPassword; 
                if (isForgotPassword) {
                    // After foget password   , redirect to login 
                    instance.logoutRedirect({
                        postLogoutRedirectUri: window.location.origin,
                       authority: b2cPolicies.authorities.signUpSignIn.authority,
                    });
                }
                else {
                    // Normal login, navigate to the layout page
                    setIsAuthenticated(true);
                    navigate('/Layout');
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, navigate]);

    // Wait until Tolgee is initialized
    if (!tolgee) {
        return <div>Loading...</div>;
    }


    return (
        <TolgeeProvider tolgee={tolgee} fallback="Loading...">
            <QueryClientProvider client={queryClient}>
                <ErrorBoundary>
                    <GlobalStateProvider>
                    {isAuthenticated ? (
                                    <Routes>
                                            <Route path="*" element={<Layout />} />
                                        </Routes>
                                    ) : (
                                        <div>Loading...</div> // Show a loader while authentication is in progress
                        )}
                    </GlobalStateProvider>
                </ErrorBoundary>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </TolgeeProvider>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <AppContent />
        </MsalProvider>
    );
};

export default App;