import { LogLevel } from "@azure/msal-browser";
 
export const b2cPolicies = {
    names: {
        signUpSignIn: 'B2C_1_GBS_SignInSignUp',
        forgotPassword: 'B2C_1_passwordreset',
        //editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://gbtrilogy.b2clogin.com/gbtrilogy.onmicrosoft.com/B2C_1_GBS_SignInSignUp',
        },
        forgotPassword: {
            authority: 'https://gbtrilogy.b2clogin.com/gbtrilogy.onmicrosoft.com/B2C_1_passwordreset',
        },
        //editProfile: {
        //    authority: 'https://gbtrilogy.b2clogin.com/gbtrilogy.onmicrosoft.com/b2c_1_edit_profile_v2',
        //},
    },
    authorityDomain: 'gbtrilogy.b2clogin.com',
};


/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: '70e36922-bdc8-4958-af04-6ba7ed561118', // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

  
export const loginRequest = {
    scopes: [],
};