import React, { useState, useEffect, useContext } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import DataTable from "react-data-table-component";
import ProjectDetailsModal from "./ProjectDetailsModal";
import { getProjectByProjectID, updateProjectStatus, deleteProject, duplicateProject, openProject, updateConfiguration } from "../../../services/Service";
import { GlobalStateContext } from "../../../context/GlobalStateContext";
export default function ProjectListModal({ isOpen, onClose, projects = [] }) {
    const [selectedProject, setSelectedProject] = useState(null);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [projectsData, setProjectsData] = useState(projects);
    const { globalState, updateGlobalState, resetTextBoxValue } = useContext(GlobalStateContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: updateConfiguration,
        onMutate: () => {
            //setLoading(true);
            queryClient.invalidateQueries("State");
        },
        onSuccess: (data) => {
            queryClient.setQueryData("ConfigurationItems", data);
            queryClient.invalidateQueries("ConfigurationSummaryItems");
            // setLoading(false);
            queryClient.invalidateQueries("State");
        },
    });

    useEffect(() => {
        if (JSON.stringify(projects) !== JSON.stringify(projectsData)) {
            setProjectsData(projects);
        }
    }, [projects]);

    if (!isOpen) return null;

    const handleOpenClick = async (row) => {
        console.log("Open project:", row);
        try {
            var ds = await openProject(row.projectID);
            console.log("Open project successfully:", row.projectID);
            console.log("Open project successfully:", ds);
            onClose();

            if (ds.instances && ds.instances["ProjectDetails"].items) {
                let items = ds.instances["ProjectDetails"].items;
                let customValues = {};
                let computedValues = {};

                Object.keys(items).forEach((key) => {
                    let item = items[key];

                    mutation.mutate({
                        configurationInstanceId: ds.instances["ProjectDetails"].instanceId || "ProjectDetails",
                        isLoading: false,
                        conflict: {
                            isConflict: false,
                            key: item.classConfiguration,
                            value: item.customValue || null,
                            selected: true, 
                        },
                    });

                    mutation.mutate({
                        configurationInstanceId: ds.instances["ProjectDetails"].instanceId || "ProjectDetails",
                        isLoading: false,
                        conflict: {
                            isConflict: false,
                            key: item.classConfiguration,
                            value: item.computedValue || "",
                            selected: true,
                        },
                    });

                    customValues = {
                        ...customValues,
                        [item.classConfiguration]: item.customValue || ""
                    };
                    computedValues = {
                        ...computedValues,
                        [item.classConfiguration]: item.computedValue || ""
                    };
                });

                updateGlobalState({
                    ...globalState,
                    textValues: customValues,
                    selectedOptions: computedValues
                });

                

                console.log("Updated global state with custom and computed values.");
            } else {
                console.error("No items found in instances.");
            }
        } catch (err) {
            console.error(err);
        }
    };


    const handleDeleteClick = async (row) => {
        try {
            await deleteProject(row.projectID);
            console.log('Project deleted successfully');
            setProjectsData((prevProjects) =>
                prevProjects.filter((project) => project.projectID !== row.projectID)
            );
        } catch (err) {
            console.error(err);
        }
    };

    const handleDuplicateClick = async (row) => {
        try {
            const duplicatedProject = await duplicateProject(row.projectID);
            console.log("Project duplicated successfully:", duplicatedProject);
            onClose();
        } catch (err) {
            console.error("Error duplicating project:", err);
        }
    };

    const handleStatusChange = async (row, newStatus) => {
        try {
            await updateProjectStatus(row.projectID, newStatus);
            setProjectsData((prevProjects) =>
                prevProjects.map((project) =>
                    project.projectID === row.projectID
                        ? { ...project, status: newStatus }
                        : project
                )
            );
        } catch (error) {
            console.error("Error updating project status:", error);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "-1":
                return "bg-wam-orange";
            case "1":
                return "bg-wam-green";
            case "0":
                return "bg-wam-red";
            default:
                return "";
        }
    };

    const columns = [
        { name: "Quote", selector: (row) => row.quoteNumber, sortable: true, width: '90px' },
        { name: "Account Search", selector: (row) => row.accountSearch, sortable: true, width: '120px' },
        { name: "User Name", selector: (row) => row.userName, sortable: true, width: '120px' },
        { name: "Project", selector: (row) => row.project, sortable: true, width: '110px' },
        { name: "Date", selector: (row) => row.date, sortable: true, width: '120px' },
        { name: "Agreement Valid From", selector: (row) => row.agreementValidFrom, sortable: true, width: '170px' },
        { name: "Total Price", selector: (row) => row.totalPrice, sortable: true, width: '120px' },
        {
            name: "Status",
            cell: (row) => {
                const backgroundColor = getStatusColor(row.status);
                return (
                    <select
                        style={{ backgroundColor }}
                        className={`text-white rounded p-1`}
                        value={row.status}
                        onChange={(e) => handleStatusChange(row, e.target.value)}
                    >
                        <option value="-1" className="bg-wam-orange">Pending</option>
                        <option value="1" className="bg-wam-green">Won</option>
                        <option value="0" className="bg-wam-red">Lost</option>
                    </select>
                );
            },
            sortable: true,
        },
        {
            name: "",
            cell: (row) => (
                <button onClick={() => handleOpenClick(row)} className="text-blue-500">
                    <i className="fas fa-folder-open"></i>
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: "",
            cell: (row) => (
                <button onClick={() => handleDeleteClick(row)} className="text-red-500">
                    <i className="fas fa-trash"></i>
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: "",
            cell: (row) => (
                <button onClick={() => handleDuplicateClick(row)} className="text-green-500">
                    <i className="fas fa-copy"></i>
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
    ];

    const handleRowClick = async (row) => {
        try {
            const projectData = await getProjectByProjectID(row.ProjectID);
            setSelectedProject(projectData);
            setIsDetailsModalOpen(true);
        } catch (error) {
            console.error("Error fetching project details:", error);
        }
    };

    const filteredProjects = projectsData.filter((project) => {
        return (
            project.quoteNumber?.includes(searchTerm) ||
            project.accountSearch?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            project.userName?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            project.project?.toString().includes(searchTerm) ||
            project.date?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
            project.agreementValidFrom?.toString().includes(searchTerm) ||
            project.totalPrice?.toString().includes(searchTerm) ||
            project.status?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-wam-lightgrey p-6 rounded shadow-lg w-full max-w-6xl max-h-[80vh] overflow-hidden">
                <h2 className="text-lg font-bold mb-4">Project List</h2>

                <input
                    type="text"
                    className="border border-gray-300 p-2 rounded mb-4 w-full"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                <div className="overflow-x-auto">
                    <DataTable
                        columns={columns}
                        data={filteredProjects}
                        pagination
                        paginationPerPage={5}
                        onRowClicked={handleRowClick}
                        noHeader
                        style={{ width: '100%' }}
                    />
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        className="bg-wam-lightgrey text-white px-4 py-2 rounded shadow hover:bg-wam-red"
                        onClick={onClose}
                    >
                        Close
                    </button>
                </div>

                {/*{selectedProject && (*/}
                {/*    <ProjectDetailsModal*/}
                {/*        isOpen={isDetailsModalOpen}*/}
                {/*        onClose={() => setIsDetailsModalOpen(false)}*/}
                {/*        project={selectedProject}*/}
                {/*    />*/}
                {/*)}*/}
            </div>
        </div>
    );
}
