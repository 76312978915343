import { useQuery, useMutation, useQueryClient, useIsFetching } from "react-query";
import { useState, useEffect, useContext, useCallback } from "react";
import ConfigurationDropdown from "./ConfigurationDropdown";
import ConfigurationSwitch from "./ConfigurationSwitch";
import ConfigurationText from "./ConfigurationText";
import ConfigurationTextBox from "./ConfigurationTextBox";
import ConfigurationRadio from "./ConfigurationRadio";
import ConfigurationButton from "./ConfigurationButton";
import ConfigurationImage from "./ConfigurationImage";

import {
    getConfigurationSubGroups, getConfigurationItems,
    updateConfiguration, setLoading, getSelectedConfigurationInstance,
    getConfigurationSummaryItems, getSelectedConfigurationModel
} from "../../../services/Service";
import { GlobalStateContext } from "../../../context/GlobalStateContext";

function GlobalLoadingIndicator() {
    const isFetching = useIsFetching({
        predicate: (query) => query.queryKey === "ConfigurationItems" && query.state.isFetching === true,
    });
    return isFetching ? true : false;
}

function Breadcrumbs({ breadcrumbItems, onBreadcrumbClick, selectedBreadcrumb, showLinks }) {

    const breadcrumbItemsList = breadcrumbItems.includes(selectedBreadcrumb) ? [...breadcrumbItems] : [...breadcrumbItems, selectedBreadcrumb];
    return (
        <nav aria-label="breadcrumb" className="py-2 px-4 bg-gray-100 rounded mb-4 bg-wam-orange">
            <ol className="flex space-x-2">
                {breadcrumbItemsList.map((item, index) => (
                    <li key={index} className="flex items-center">
                        {showLinks ? (
                            <button
                                className={`text-gray-600 ${index < breadcrumbItemsList.length - 1 ? 'hover:text-blue-500' : ''} ${selectedBreadcrumb === item ? 'font-bold text-blue-500' : ''}`}
                                onClick={() => onBreadcrumbClick(item)}
                            >
                                {item}
                            </button>
                        ) : (
                            <span className="text-gray-600">{item}</span>
                        )}
                        {index < breadcrumbItemsList.length - 1 && (
                            <span className="mx-2 text-gray-400">/</span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
export default function ConfigurationItem({showDurationTextInput, setShowDurationTextInput}) {
    const queryClient = useQueryClient();
    const { globalState, updateGlobalState, resetTextBoxValue } = useContext(GlobalStateContext);
    const { selectedOptions, selectedMultiSelectOptions, textValues, resetActiveBreadcrumb } = globalState;
    const [expandedSubGroup, setExpandedSubGroup] = useState(null);
    const [activeSubGroup, setActiveSubGroup] = useState(null);
    const [selectedBreadcrumb, setSelectedBreadcrumb] = useState(null);
    const [showBreadcrumbLinks, setShowBreadcrumbLinks] = useState(false);
    const [expandedSubGroups, setExpandedSubGroups] = useState({});

    const toggleSubGroup = (subGroup) => {
        setExpandedSubGroups((prevState) => ({
            ...prevState,
            [subGroup]: !prevState[subGroup], 
        }));
    };


    const handleSingleSelectChange = (key, selectedOption, id) => {
        updateGlobalState({ selectedOptions: { ...selectedOptions, [key]: key === selectedOption ? id : selectedOption}, 
            resetActiveBreadcrumb: false
        });
        selectedOption !== undefined && moveToNextSubGroup();
        if (selectedOption === 'SUBCATEGORY') {
            setActiveSubGroup('Resource Requirement');
        }
        if (selectedOption === 'tsDAILY' || selectedOption === 'tsHOURLY'){
            setShowDurationTextInput(true);
        } else if (selectedOption === 'tsMONTHLY') {
            setShowDurationTextInput(false);
        }
    };

    const handleMultiSelectChange = (key, selectedOptions) => {
        if (!selectedOptions || selectedOptions.length === 0) {
            handleSingleSelectChange(key, "select");
        }
        updateGlobalState({ selectedMultiSelectOptions: { ...selectedMultiSelectOptions, [key]: selectedOptions } });
    };

    const handleTextChange = (key, value) => {
        updateGlobalState({ textValues: { ...textValues, [key]: value } });
    };

    //const resetValues = () => {
    //    updateGlobalState({
    //        selectedOptions: "select",
    //        selectedMultiSelectOptions: [],
    //        textValues: ""
    //    });
    //};


    const resetValues = useCallback(() => {
        updateGlobalState({
            selectedOptions: "select",
            selectedMultiSelectOptions: [],
            textValues: globalState.initialTextValues, 
        });
    }, [updateGlobalState, globalState.initialTextValues]);

    const moveToNextSubGroup = () => {
        const currentIndex = SubGroups.findIndex(
            (subGroup) => subGroup.subGroup === activeSubGroup
        );
        const nextIndex = currentIndex + 1;
        if (nextIndex < SubGroups.length) {
            setActiveSubGroup(SubGroups[nextIndex].subGroup);
            setSelectedBreadcrumb(SubGroups[nextIndex].subGroup);
        }
    };

    const configItems = useQuery("ConfigurationItems", getConfigurationItems, {
        refetchOnWindowFocus: false,
    });

    const { data: selectedInstance } = useQuery(
        "SelectedConfigurationInstance",
        getSelectedConfigurationInstance,
        {
            refetchOnWindowFocus: false,
        }
    );
    const { data: selectedModel } = useQuery(
        "SelectedConfigurationModel",
        getSelectedConfigurationModel,
        {
            refetchOnWindowFocus: false,
        }
    );
    const mutation = useMutation({
        mutationFn: updateConfiguration,
        onMutate: () => {
            setLoading(true);
            queryClient.invalidateQueries("State");
        },
        onSuccess: (data) => {
            queryClient.setQueryData("ConfigurationItems", data);
            queryClient.invalidateQueries("ConfigurationSummaryItems");
            setLoading(false);
            queryClient.invalidateQueries("State");
        },
    });


    const SubGroups = getConfigurationSubGroups(configItems.data);
    const isLoading = GlobalLoadingIndicator();

    // Item grid view code

    const { data: summaryItems } = useQuery(
        "ConfigurationSummaryItems",
        getConfigurationSummaryItems,
        {
            refetchOnWindowFocus: false,
        }
    );

    const extractInstanceFromExpression = (expression, regex) => {
        const match = expression.match(regex);
        return match ? match[1] : null;
    };

    const groupItemsByInstanceAndSubGroup = (items, instance) => {
        const filteredKeys = Object.keys(items)
            .filter(key => {
                const item = items[key];
                return item.description === item.subGroup && isValidValue(item.computedValue);
            })
            .map(key => items[key].subGroup);

        if (instance.configurationInstanceId === "Summary") {
            const grouped = {};
            Object.keys(items).forEach((key) => {
                const item = items[key];
                const subGroupKey = item.subGroup || "UnknownSubGroup";
                if (filteredKeys.includes(subGroupKey)) {
                    if (!grouped[subGroupKey]) {
                        grouped[subGroupKey] = [];
                    }
                    grouped[subGroupKey].push({ key, ...item });
                }
            });
            return grouped;
        }
        if (!instance) return {};

        const grouped = {};
        Object.keys(items).forEach((key) => {
            const item = items[key];
            const regex1 = /Config\["([^"]+)"\,"[^"]+"\]\.(.*)/;
            const regex2 = /Greybeard\.Join\(Config\["([^"]+)"\,"[^"]+"\]\.SelectedItems\)/;
            const expressionInstance1 = extractInstanceFromExpression(item.onValueExpression, regex1);
            const expressionInstance2 = extractInstanceFromExpression(item.onValueExpression, regex2);

            if (expressionInstance1 === instance.configurationInstanceId || expressionInstance2 === instance.configurationInstanceId) {
                const subGroupKey = item.subGroup || "UnknownSubGroup";
                if (filteredKeys.includes(subGroupKey)) {
                    if (!grouped[subGroupKey]) {
                        grouped[subGroupKey] = [];
                    }
                    grouped[subGroupKey].push({ key, ...item });
                }
            }
        });
        return grouped;
    };

    const isValidValue = (value) => value !== null && value !== "-" && value.trim() !== "";

    const groupedItems = summaryItems ? groupItemsByInstanceAndSubGroup(summaryItems, { configurationInstanceId: "Summary" }) : {};
    const shouldShowAllSummary = true;

    useEffect(() => {
        let shouldReset = false;

        Object.keys(selectedMultiSelectOptions).forEach(key => {
            if (!selectedMultiSelectOptions[key] || selectedMultiSelectOptions[key].length === 0) {
                shouldReset = true;
            }
        });

        if (shouldReset) {
            resetValues();
        }
    }, [selectedMultiSelectOptions, resetValues]);




    useEffect(() => {
        //var f = mutation && mutation.mutate();
        console.log(configItems.data);
    }, [configItems.data]);

    useEffect(() => {
        if (SubGroups && SubGroups.length > 0 && (activeSubGroup === null || resetActiveBreadcrumb === true)) {
            setActiveSubGroup(SubGroups[0]?.subGroup);
            setSelectedBreadcrumb(SubGroups[0].subGroup);
        }
    }, [SubGroups, activeSubGroup, resetActiveBreadcrumb]);


    useEffect(() => {
        if (selectedModel?.configurationModelId === 'BPO2' && selectedInstance?.configurationInstanceId === 'Quotation') {
            setShowBreadcrumbLinks(true);
        } else {
            setShowBreadcrumbLinks(false);
        }
    }, [selectedModel, selectedInstance]);

    const breadcrumbItems = Array.from(new Set(
        Object.keys(selectedOptions)
            .filter(option => selectedOptions[option] !== "select")
            .map(key => {
                const configItem = configItems.data[key];
                return configItem ? configItem.subGroup : activeSubGroup;
            })
    ));


    const handleBreadcrumbClick = (subGroup) => {
        setActiveSubGroup(subGroup);
        setSelectedBreadcrumb(subGroup);
    };

    if (selectedInstance.configurationInstanceId === "Summary") {
        return null;
    }
    return (
        <>
            {selectedModel?.configurationModelId === 'BPO2' && selectedInstance?.configurationInstanceId === 'Quotation' && selectedInstance !== "Summary" ? (
                <>
                    {/* Existing Functionality */}
                    <Breadcrumbs
                        breadcrumbItems={breadcrumbItems}
                        onBreadcrumbClick={handleBreadcrumbClick}
                        selectedBreadcrumb={selectedBreadcrumb}
                        showLinks={showBreadcrumbLinks}
                    />
                    {SubGroups &&
                        SubGroups.map((subGroup) => (
                            <div
                                key={subGroup.subGroup}
                                className={`overflow-hidden rounded-lg bg-wam-lightgrey shadow sm:w-full sm:mx-0 transition-all duration-300 ease-in-out ${activeSubGroup === subGroup.subGroup ? "h-auto" : "h-10"
                                    }`}
                                hidden={
                                    selectedInstance.configurationInstanceId === 'Quotation' && activeSubGroup !== subGroup.subGroup
                                }
                            >
                                {/* Subgroup Header */}
                                <div className="px-2 py-2 sm:px-2 bg-wam-darkgrey text-wam-white font-medium text-indigo-600 cursor-pointer flex justify-between items-center relative">
                                    <span className="flex items-center relative">
                                        {subGroup.subGroup}
                                        <span className="ml-2 relative group">
                                            <i className="fas fa-info-circle text-wam-white text-xl"></i>
                                            <span className="absolute top-1/2 left-full ml-2 transform -translate-y-1/2 w-max scale-0 rounded bg-black p-2 text-xxs text-white transition-transform duration-150 ease-in-out group-hover:scale-100">
                                                {subGroup.subGroup}
                                            </span>
                                        </span>
                                    </span>
                                </div>

                                {/* Subgroup Content */}
                                <div className="px-2 py-2">
                                    {configItems.isLoading && activeSubGroup === subGroup.subGroup && (
                                        <div className="flex h-full justify-start items-center">
                                            <svg className="w-12 h-12 animate-spin text-indigo-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                {/* SVG paths */}
                                            </svg>
                                        </div>
                                    )}
                                    {!configItems.isLoading && configItems.data && Object.keys(configItems.data)
                                        .sort((a, b) => a.order - b.order)
                                        .map((cikey) => {
                                            const configurationItem = configItems.data[cikey];
                                            return (
                                                configurationItem.subGroup === subGroup.subGroup && (
                                                    <div key={cikey} className={isLoading ? "cursor-not-allowed opacity-50" : "relative"} style={{ padding: "0px 0px" }}>
                                                        {/* Configuration Components */}
                                                        {configurationItem.visible && configurationItem.editorType === "ImageArea" && (
                                                            <ConfigurationImage
                                                                configurationItem={configurationItem}
                                                                mutation={mutation}
                                                                showLables={true}
                                                                selectedOptions={selectedMultiSelectOptions[cikey]}
                                                                onSingleSelectChange={(selectedOption) => handleSingleSelectChange(cikey, selectedOption)}
                                                                onMultiSelectChange={(selectedOptions) => handleMultiSelectChange(cikey, selectedOptions)}
                                                            />
                                                        )}

                                                        {configurationItem.visible && configurationItem.editorType === "DropDown" && (
                                                            <ConfigurationDropdown
                                                                configurationItem={configurationItem}
                                                                mutation={mutation}
                                                                showLables={true}
                                                                selectedSingleOption={selectedOptions[cikey]}
                                                                selectedOptions={selectedMultiSelectOptions[cikey]}
                                                                onSingleSelectChange={(selectedOption) => handleSingleSelectChange(cikey, selectedOption)}
                                                                onMultiSelectChange={(selectedOptions) => handleMultiSelectChange(cikey, selectedOptions)}
                                                            />
                                                        )}

                                                        {configurationItem.visible && configurationItem.editorType === "Button" && (
                                                            <ConfigurationButton
                                                                configurationItem={configurationItem}
                                                                mutation={mutation}
                                                                selectedSingle={selectedOptions[cikey]}
                                                                selectedMulti={selectedMultiSelectOptions[cikey]}
                                                                onSelectMultiOptions={(options) => handleMultiSelectChange(cikey, options)}
                                                                onSingleSelectChange={(options, id) => handleSingleSelectChange(cikey, options, id)}
                                                            />
                                                        )}

                                                        {configurationItem.visible && configurationItem.editorType === "Switch" && (
                                                            <ConfigurationSwitch
                                                                configurationItem={configurationItem}
                                                                mutation={mutation}
                                                            />
                                                        )}

                                                        {configurationItem.visible && configurationItem.editorType === "Radio" && (
                                                            <ConfigurationRadio
                                                                configurationItem={configurationItem}
                                                                mutation={mutation}
                                                            />
                                                        )}

                                                        {configurationItem.visible && configurationItem.editorType === "Checkbox" && (
                                                            <div key={"CHECKBOX" + cikey} className="relative flex items-start">
                                                                <div className="flex h-5 items-center">
                                                                    <input
                                                                        id="candidates"
                                                                        name="candidates"
                                                                        type="checkbox"
                                                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm">
                                                                    <label htmlFor="candidates" className="font-medium text-gray-700">
                                                                        Candidates
                                                                    </label>
                                                                    <p className="text-gray-500">
                                                                        Get notified when a candidate applies for a job.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {configurationItem.visible && configurationItem.editorType === "TextBox" && 
                                                        (showDurationTextInput || configurationItem.classConfiguration === 'RQ') && (
                                                            <ConfigurationTextBox
                                                                configurationItem={configurationItem}
                                                                mutation={mutation}
                                                                value={textValues[cikey] || ""}
                                                                onTextChange={(value) => handleTextChange(cikey, value)}
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            );
                                        })}
                                </div>
                            </div>
                        ))}
                </>
            ) : (
                <>
                        {/* Expanded all subgroups with toggle functionality */}
                        {SubGroups &&
                            SubGroups.map((subGroup) => (
                                <div
                                    key={subGroup.subGroup}
                                    className="overflow-hidden rounded-lg bg-wam-lightgrey shadow sm:w-full sm:mx-0 transition-all duration-300 ease-in-out h-auto"
                                >
                                    <div className="px-2 py-2 sm:px-2 bg-wam-darkgrey text-wam-white font-medium text-indigo-600 cursor-pointer flex justify-between items-center relative">
                                        <span className="flex items-center relative">
                                            {subGroup.subGroup}
                                            <span className="ml-2 relative group">
                                                <i className="fas fa-info-circle text-wam-white text-xl"></i>
                                                <span className="absolute top-1/2 left-full ml-2 transform -translate-y-1/2 w-max scale-0 rounded bg-black p-2 text-xxs text-white transition-transform duration-150 ease-in-out group-hover:scale-100">
                                                    {subGroup.subGroup}
                                                </span>
                                            </span>
                                        </span>
                                    </div>

                                    <div className="grid grid-cols-2 gap-4 px-4 py-2">
                                        {configItems.isLoading && (
                                            <div className="flex h-full justify-start items-center">
                                                <svg className="w-12 h-12 animate-spin text-indigo-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    {/* SVG paths */}
                                                </svg>
                                            </div>
                                        )}
                                        {!configItems.isLoading && configItems.data && Object.keys(configItems.data)
                                            .sort((a, b) => a.order - b.order)
                                            .map((cikey) => {
                                                const configurationItem = configItems.data[cikey];
                                                return (
                                                    configurationItem.subGroup === subGroup.subGroup && (
                                                        <div key={cikey} className={isLoading ? "cursor-not-allowed opacity-50" : "relative"}>
                                                            {/* Configuration Components */}
                                                            {configurationItem.visible && configurationItem.editorType === "ImageArea" && (
                                                                <ConfigurationImage
                                                                    configurationItem={configurationItem}
                                                                    mutation={mutation}
                                                                    showLables={true}
                                                                    selectedOptions={selectedMultiSelectOptions[cikey]}
                                                                    onSingleSelectChange={(selectedOption) => handleSingleSelectChange(cikey, selectedOption)}
                                                                    onMultiSelectChange={(selectedOptions) => handleMultiSelectChange(cikey, selectedOptions)}
                                                                />
                                                            )}

                                                            {configurationItem.visible && configurationItem.editorType === "DropDown" && (
                                                                <ConfigurationDropdown
                                                                    configurationItem={configurationItem}
                                                                    mutation={mutation}
                                                                    showLables={true}
                                                                    selectedSingleOption={selectedOptions[cikey]}
                                                                    selectedOptions={selectedMultiSelectOptions[cikey]}
                                                                    onSingleSelectChange={(selectedOption) => handleSingleSelectChange(cikey, selectedOption)}
                                                                    onMultiSelectChange={(selectedOptions) => handleMultiSelectChange(cikey, selectedOptions)}
                                                                />
                                                            )}

                                                            {configurationItem.visible && configurationItem.editorType === "Button" && (
                                                                <ConfigurationButton
                                                                    configurationItem={configurationItem}
                                                                    mutation={mutation}
                                                                    selectedSingle={selectedOptions[cikey]}
                                                                    selectedMulti={selectedMultiSelectOptions[cikey]}
                                                                    onSelectMultiOptions={(options) => handleMultiSelectChange(cikey, options)}
                                                                    onSingleSelectChange={(options) => handleSingleSelectChange(cikey, options)}
                                                                />
                                                            )}

                                                            {configurationItem.visible && configurationItem.editorType === "Switch" && (
                                                                <ConfigurationSwitch
                                                                    configurationItem={configurationItem}
                                                                    mutation={mutation}
                                                                />
                                                            )}

                                                            {configurationItem.visible && configurationItem.editorType === "Radio" && (
                                                                <ConfigurationRadio
                                                                    configurationItem={configurationItem}
                                                                    mutation={mutation}
                                                                />
                                                            )}

                                                            {configurationItem.visible && configurationItem.editorType === "Checkbox" && (
                                                                <div key={"CHECKBOX" + cikey} className="relative flex items-start">
                                                                    <div className="flex h-5 items-center">
                                                                        <input
                                                                            id="candidates"
                                                                            name="candidates"
                                                                            type="checkbox"
                                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                        />
                                                                    </div>
                                                                    <div className="ml-3 text-sm">
                                                                        <label htmlFor="candidates" className="font-medium text-gray-700">
                                                                            Candidates
                                                                        </label>
                                                                        <p className="text-gray-500">
                                                                            Get notified when a candidate applies for a job.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {configurationItem.visible && configurationItem.editorType === "TextBox" && (
                                                                <ConfigurationTextBox
                                                                    configurationItem={configurationItem}
                                                                    mutation1={mutation}
                                                                    value={textValues[cikey] || ""}
                                                                    onTextChange={(value) => handleTextChange(cikey, value)}
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                );
                                            })}
                                    </div>
                                </div>
                            ))}


                </>
            )}
        </>
    );

}
