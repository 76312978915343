import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useQuery, useIsFetching, useMutation, useQueryClient } from 'react-query';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import ConfigurationInstance from "../../features/Configuration/components/ConfigurationInstance";
import ConfigurationItem from "../../features/Configuration/components/ConfigurationItem";
import ConfigurationTable from "../../features/Configuration/components/ConfigurationTable";
import ConfigurationSummary from "../../features/Configuration/components/ConfigurationSummary";
import ProjectListModal from "./../../features/Configuration/components/ProjectListModal";
import ConfirmModal from "./../../features/Configuration/components/ConfirmModal";
//import RevisionConfirmModal from "./../../features/Configuration/components/RevisionConfirmModal";
import StatusModal from "./../../features/Configuration/components/StatusModal";
import ProjectCloseModal from "./../../features/Configuration/components/ProjectCloseModal";
import {
    getState, getSelectedConfigurationInstance, setSelectedConfigurationInstance, isConfigurationSummary, getConfigurationSummaryItems,
    updateSelectedProjectItem, getSelectedConfigurationModel, saveProject, getProjects, getProjectItems, deleteProjectItemBySKU,
    getProjectItem, addDuplicatedItem, closeProject, generateDocumentService, getConfigurationItems
} from "../../services/Service";
import { GlobalStateContext } from "../../context/GlobalStateContext";
import DataTable from "react-data-table-component";
import { addToProject, openProject } from "../../services/Service"; 
import { forEach } from 'lodash';
//import { useConfigurationState } from "../hooks/useConfigurationState";
function GlobalLoadingIndicator() {
    const isFetching = useIsFetching();
    return isFetching > 0 || getState().isLoading;
}
const ITEMS_PER_PAGE = 5;
const ItemSection = () => {
    const { accounts } = useMsal();
    const username = accounts[0]?.idTokenClaims.extension_FullName;
    const [configurationInstanceId, setConfigurationInstanceId] = useState(null);
    const [addToProjectClicked, setAddToProjectClicked] = useState(false);
    const [hasComputedValue, setHasComputedValue] = useState(false);
    const [navigationComplete, setNavigationComplete] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    //const [showRevisionPopup, setShowRevisionPopup] = useState(false);
    const [statusMessage, setStatusMessage] = useState(null);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [isProjectModalOpen, setProjectModalOpen] = useState(false);
    const [isProjectCloseModalOpen, setProjectCloseModalOpen] = useState(false);
    const [isProjectDuplicate, setProjectDuplicate] = useState(false);
    const [isEditButtonClick, setEditButtonClick] = useState(false);
    const [isCloseButtonClick, setCloseButtonClick] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projectItems, setProjectItems] = useState([]);
    const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
    const [refreshConfigSummary, setRefreshConfigSummary] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [showDurationTextInput, setShowDurationTextInput] = useState(false);
    //const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    //const [isSaveClicked, setIsSaveClicked] = useState(false);
    //const dropdownRef = useRef(null);
    const recordsPerPage = 3;
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { globalState, updateGlobalState, resetTextBoxValue } = useContext(GlobalStateContext);
    const { selectedOptions } = globalState;

    // Mutations
    var mutation = useMutation(setSelectedConfigurationInstance, {
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries("State");
            queryClient.invalidateQueries("SelectedConfigurationInstance");
            queryClient.invalidateQueries("ConfigurationItems");
            queryClient.invalidateQueries("SubGroups");
            queryClient.invalidateQueries("IsConfigurationSummary");
        },
    });

    const { data: summaryItems, isLoading: isSummaryLoading } = useQuery(
        "ConfigurationSummaryItems",
        getConfigurationSummaryItems,
        {
            refetchOnWindowFocus: false,
        }
    );
    const isValidValue = (computedValue) => {
        return computedValue !== null && computedValue !== "-" && computedValue.trim() !== "" && computedValue !== "£ 0" && computedValue !== "£0";
    };

    const { data: stateData } = useQuery("State", () => Promise.resolve(getState()), {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
    });

    const { data: selectedConfigInstanceData } = useQuery(
        "SelectedConfigurationInstance",
        () => new Promise((resolve) => {
            resolve(getSelectedConfigurationInstance());
        }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: false,
        }
    );

    const { data: selectedConfigModelData } = useQuery(
        "SelectedConfigurationModel",
        () => new Promise((resolve) => {
            resolve(getSelectedConfigurationModel());
        }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: false,
        }
    );


    const { data: isConfigSummaryData } = useQuery(
        "IsConfigurationSummary",
        () => new Promise((resolve) => {
            resolve(isConfigurationSummary());
        }),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchOnReconnect: true,
            retry: false,
        }
    );

    const { data: selectedModel } = useQuery(
        "SelectedConfigurationModel",
        getSelectedConfigurationModel,
        {
            refetchOnWindowFocus: false,
        }
    );

    const isLoading = GlobalLoadingIndicator();

    useEffect(() => {
        if (!isSummaryLoading && summaryItems && typeof summaryItems === 'object' && Object.keys(summaryItems).length > 0) {
            //const hasValidMultiSelection = Object.values(summaryItems).some(item =>
            //    item.selectionMode === 'SingleValue' && isValidValue(item.computedValue)
            //);
            const hasValidMultiSelection = Object.values(summaryItems).some(item =>
                isValidValue(item.computedValue)
            );
            setHasComputedValue(hasValidMultiSelection);
        } else {
            setHasComputedValue(false);
        }
    }, [summaryItems, isSummaryLoading]);

    const handleAddToProject = async () => {
        setAddToProjectClicked(true);
        try {
            const ModelId = selectedConfigModelData?.configurationModelId;

            const dataWithoutState = await addToProject(ModelId);
            updateGlobalState((prevState) => ({
                selectedOptions: {
                    ...prevState.selectedOptions || {},
                    CATEGORY: '',
                    SUBCATEGORY: '',
                    DURATIONTYPE: ''
                },
                selectedMultiSelectOptions: {},
                textValues: {
                    ...prevState.textValues || {},
                    RQ: ''
                }
            }));
            console.log('Successfully added to project.');
            fetchProjectItems();
        } catch (error) {
            console.error('Error while adding to project:', error);
        }
        setShowDurationTextInput(false);
    };
    const fetchProjectItems = async () => {
        try {
            const items = await getProjectItems();
            console.log('Fetched Project Items:', items);
            setProjectItems(items || []);
        } catch (err) {
            setError('Error fetching project items');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const handleViewInProject = async () => {
        setConfigurationInstanceId('ProjectDetails');
        mutation.mutate({
            configurationInstanceId: 'ProjectDetails',
        });
        setNavigationComplete(true);
        fetchProjectItems();
        updateGlobalState({ resetActiveBreadcrumb: true });
    };

    const handleBackToAddProject = () => {
        setAddToProjectClicked(false);
        setConfigurationInstanceId('Quotation');
        mutation.mutate({
            configurationInstanceId: 'Quotation',
        });
        updateGlobalState({ resetActiveBreadcrumb: true });
        setEditButtonClick(false);
        setShowDurationTextInput(false);
    };

    const resetValues = useCallback(() => {
        updateGlobalState({
            selectedOptions: "",
            selectedMultiSelectOptions: [],
            textValues: globalState.initialTextValues,
        });
    }, [updateGlobalState, globalState.initialTextValues]);

    const handleSaveClick = () => {
        updateGlobalState({ resetActiveBreadcrumb: true });
        setAddToProjectClicked(false);
        setEditButtonClick(false);
        setShowPopup(true);
        //setIsSaveClicked(true); 
        //setIsDropdownOpen(false);
    };

    //const handleRevisionClick = () => {
    //    updateGlobalState({ resetActiveBreadcrumb: true });
    //    setAddToProjectClicked(false);
    //    setEditButtonClick(false);
    //    setShowRevisionPopup(true);
    //    setIsDropdownOpen(false);
    //    console.log("Revision clicked");
    //};

    const handleConfirm = async () => {
        setShowPopup(false);


        try {
            const isSaved = await saveProject(username);
            if (isSaved) {
                setStatusMessage("Project saved successfully!");
                await fetchProjectItems();
            } else {
                setStatusMessage("Failed to save project.");
            }
        } catch (error) {
            setStatusMessage("Error in saving project: " + error.message);
        }

        setShowStatusModal(true);
    };

    //const handleRevisionConfirm = async () => {
    //    setShowRevisionPopup(false);


    //    try {
    //        const isSaved = await saveProject(username);
    //        if (isSaved) {
    //            setStatusMessage("Project Rvision saved successfully!");
    //            await fetchProjectItems();
    //        } else {
    //            setStatusMessage("Failed to save project.");
    //        }
    //    } catch (error) {
    //        setStatusMessage("Error in saving project: " + error.message);
    //    }

    //    setShowStatusModal(true);
    //};



    const handleCancel = () => {
        setShowPopup(false);
    };

    //const handleRevisionCancel = () => {
    //    setShowRevisionPopup(false);
    //};

    const handleCloseStatusModal = () => {
        setShowStatusModal(false);
        setStatusMessage(null);
    };
    const handleSaveAndClose = async () => {

        try {
            await handleConfirm();
            await closeProject();
            await fetchProjectItems();
            console.log('Close project with saving');
            // resetValues();
        } catch (err) {
            console.log('Error Closing project with saving');
        }

        setProjectDuplicate(false);
        setProjectCloseModalOpen(false);
        setEditButtonClick(false);
        setCloseButtonClick(true);
    };
    const handleclosemodel = async () => {
        await fetchProjectItems();
        setProjectCloseModalOpen(false);
        
    };
    const handleCloseWithoutSaving = async () => {
        try {
            await closeProject();
            await fetchProjectItems();
            resetValues()
            console.log('Close project without saving');
        } catch (err) {
            console.log('Error Closing project without saving');
        }

        setProjectDuplicate(false);
        setProjectCloseModalOpen(false);
        setEditButtonClick(false);
        setCloseButtonClick(true);
        setRefreshConfigSummary((prev) => !prev);
    };

    const openProjectCloseModal = () => {
        //setIsSaveClicked(false);
        //setIsDropdownOpen(false);
        setProjectCloseModalOpen(true);
        setAddToProjectClicked(false);
    };

    const closeProjectCloseModal = async () => {
        try {
            await closeProject();
            console.log('Close project without saving');
        } catch (err) {
            console.log('Error Closing project without saving');
        }

        setProjectDuplicate(false);
        setProjectCloseModalOpen(false);
    };

    const openProjectModal = async () => {
        setProjectModalOpen(true);
        try {
            const fetchedProjects = await getProjects();
            setProjects(fetchedProjects);
            
        } catch (error) {
            console.error("Error fetching projects", error);
        }
    };

    const closeProjectModal = async () => {
        try {
            const fetchedProjects = await fetchProjectItems();
            setProjects(fetchedProjects);
        } catch (error) {
            console.error("Error fetching projects", error);
        }
        setProjectModalOpen(false);

    };

    const handleUpdateResource = async () => {
        try {
            if (!selectedRowForEdit) {
                throw new Error('No row selected for editing.');
            }

            console.log("Updating resource for row:", selectedRowForEdit);
            const ModelId = selectedConfigModelData?.configurationModelId;
            var updatedProject = await updateSelectedProjectItem(ModelId, selectedRowForEdit.sku);
            await fetchProjectItems();
            setConfigurationInstanceId('ProjectDetails');
            if (updatedProject.instances && updatedProject.instances["ProjectDetails"].items) {
                let items = updatedProject.instances["ProjectDetails"].items;
                let customValues = {};
                let computedValues = {};

                Object.keys(items).forEach((key) => {
                    let item = items[key];

                    mutation.mutate({
                        configurationInstanceId: updatedProject.instances["ProjectDetails"].instanceId || "ProjectDetails",
                        isLoading: false,
                        conflict: {
                            isConflict: false,
                            key: item.classConfiguration,
                            value: item.customValue || null,
                            selected: true,
                        },
                    });

                    mutation.mutate({
                        configurationInstanceId: updatedProject.instances["ProjectDetails"].instanceId || "ProjectDetails",
                        isLoading: false,
                        conflict: {
                            isConflict: false,
                            key: item.classConfiguration,
                            value: item.computedValue || "",
                            selected: true,
                        },
                    });

                    customValues = {
                        ...customValues,
                        [item.classConfiguration]: item.customValue || ""
                    };
                    computedValues = {
                        ...computedValues,
                        [item.classConfiguration]: item.computedValue || ""
                    };
                });

                updateGlobalState({
                    ...globalState,
                    textValues: customValues,
                    //textValues: customValues,
                    // selectedOptions: computedValues
                    selectedOptions: {},
                    selectedMultiSelectOptions: {},
                    //textValues: {}, 
                });

                console.log("Updated project with custom and computed values.");
            } else {
                console.error("No items found in instances.");
            }
        } catch (err) {
            setError('Error update resource');
        }
        setShowDurationTextInput(false);
    }

    // item grid using projectitem

    async function handleEdit(row, sku, pageIndex) {
        updateGlobalState({
            selectedOptions: {},
            selectedMultiSelectOptions: {},
            textValues: {},
        });
        try {
            setEditButtonClick(true);
            setShowDurationTextInput(true);
            const actualIndex = (currentPage - 1) * recordsPerPage + pageIndex;
            var dd = await getProjectItem(actualIndex);
            setSelectedRowForEdit(row);

            setConfigurationInstanceId('Quotation');
            //mutation.mutate({
            //    configurationInstanceId: 'Quotation',
            //});

            var ds = dd.State.Instances["Quotation"];
            Object.keys(ds).forEach((key) => {
                console.log(`${key}: ${ds[key]}`);
                if (key === "Items") {
                    let items = ds[key];
                    var ll = {};
                    var ll2 = {};

                    Object.keys(items).forEach((ikey) => {
                        let itt = items[ikey];
                        if (itt.ClassConfiguration === 'CATEGORY' || itt.ClassConfiguration === 'SUBCATEGORY' || itt.ClassConfiguration === 'DURATIONTYPE') {
                            updateGlobalState({ selectedOptions: { ...selectedOptions, [itt.ClassConfiguration]: itt.ComputedValue}});
                        }
                        mutation.mutate({
                            configurationModelId: dd.ModelId,
                            configurationInstanceId: dd.State.Instances["Quotation"].InstanceId || "Quotation",
                            isLoading: false,
                            conflict: {
                                isConflict: false,
                                key: itt.ClassConfiguration,
                                value: itt.CustomValue || null,
                                selected: true,
                            },
                        });


                        mutation.mutate({
                            configurationModelId: dd.ModelId,
                            configurationInstanceId: dd.State.Instances["Quotation"].InstanceId || "Quotation",
                            isLoading: false,
                            conflict: {
                                isConflict: false,
                                key: itt.ClassConfiguration,
                                value: itt.ComputedValue || "",
                                selected: true,
                            },
                        });

                        ll = {
                            ...ll,
                            [itt.ClassConfiguration]: itt.CustomValue || ""
                        };
                        ll2 = {
                            ...ll2,
                            [itt.ClassConfiguration]: itt.ComputedValue || ""
                        };
                    });

                    const breadcrumbItems = Object.keys(items).filter((key) => {
                        const item = items[key];
                        return item.CustomValue || item.ComputedValue; 
                    });

                    updateGlobalState({
                        ...globalState,
                        breadcrumbItems: breadcrumbItems,
                        selectedBreadcrumb: breadcrumbItems.length > 0 ? breadcrumbItems[0] : null,
                        textValues: ll,
                        selectedOptions: ll2
                    });
                }
            });

        } catch (error) {
            console.error("Error editing item:", error);
        }
    }

    async function handleDelete(sku, pageIndex) {
        try {
            const actualIndex = (currentPage - 1) * recordsPerPage + pageIndex;
            await deleteProjectItemBySKU(sku, actualIndex);
            //await fetchProjectItems();
            setProjectItems((prevItems) => {
                const updatedItems = [...prevItems];
                updatedItems.splice(actualIndex, 1);
                return updatedItems;
            });
            setProjectDuplicate(false);
            //resetValues();
        } catch (error) {
            console.error("Error deleting item:", error);
        }
    }
    async function handleDuplicate(row) {
        try {
            setProjectDuplicate(false);
            const duplicatedRow = { ...row };

            duplicatedRow.sku = `${row.sku}-copy`; 

            setProjectItems((prevItems) => [...prevItems, duplicatedRow]);

            await addDuplicatedItem(duplicatedRow);
            setProjectDuplicate(false);
        } catch (error) {
            console.error("Error duplicating item:", error);
        }
    }


    const columns = [
        //{
        //    name: "SKU",
        //    selector: (row) => row.sku,
        //    sortable: true,
        //    cell: (row) => (
        //        <div className="relative group">
        //            <span>{row.sku}</span>
        //            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
        //                {row.sku}
        //            </div>
        //        </div>
        //    )
        //},
        //{
        //    name: "Model",
        //    selector: (row) => row.modelId,
        //    sortable: true,
        //    cell: (row) => (
        //        <div className="relative group">
        //            <span>{row.modelId}</span>
        //            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
        //                {row.modelId}
        //            </div>
        //        </div>
        //    )
        //},
        //{
        //    name: "Description",
        //    selector: (row) => row.description,
        //    cell: (row) => (
        //        <div className="relative group">
        //            <span>{row.description}</span>
        //            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
        //                {row.description}
        //            </div>
        //        </div>
        //    )
        //},
        {
            name: "Category",
            selector: (row) => row.category,
            cell: (row) => (
                <div className="relative group">
                    <span>{row.category}</span>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
                        {row.category}
                    </div>
                </div>
            )
        },
        {
            name: "Sub Category",
            selector: (row) => row.subCategory,
            cell: (row) => (
                <div className="relative group">
                    <span>{row.subCategory}</span>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
                        {row.subCategory}
                    </div>
                </div>
            )
        },
        {
            name: "No of Resources",
            selector: (row) => row.quantity,
            cell: (row) => (
                <div className="relative group">
                    <span>{row.quantity}</span>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
                        {row.quantity}
                    </div>
                </div>
            )
        },
        {
            name: "Duration Type",
            selector: (row) => row.durationType,
            cell: (row) => (
                <div className="relative group">
                    <span>{row.durationType}</span>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
                        {row.durationType}
                    </div>
                </div>
            )
        },
        {
            name: "Duration",
            selector: (row) => row.duration,
            cell: (row) => (
                <div className="relative group">
                    <span>{row.duration}</span>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
                        {row.duration}
                    </div>
                </div>
            )
        },
        {
            name: "Unit Price",
            selector: (row) =>
                row.price
                    ? `$${row.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`
                    : "N/A",
            sortable: true,
            cell: (row) => (
                <div className="relative group">
                    <span>{row.quantity && row.price ? `$${(row.price / row.quantity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "N/A"}</span>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
                        {row.quantity && row.price ? `$${(row.price / row.quantity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "N/A"}
                    </div>
                </div>
            )
        },
        {
            name: "Total Price",
            selector: (row) =>
                row.price
                    ? `$${row.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`
                    : "N/A",
            sortable: true,
            cell: (row) => (
                <div className="relative group">
                    <span>{row.price ? `$${row.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "N/A"}</span>
                    <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 hidden group-hover:block bg-wam-lightgrey text-white text-xs p-1 rounded-md whitespace-nowrap z-10">
                        {row.price ? `$${row.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "N/A"}
                    </div>
                </div>
            )
        },
        
        {
            name: "",
            cell: (row, index) => (
                <button
                    onClick={() => handleEdit(row,row.sku, index)}
                    className="text-blue-500 hover:text-blue-700">
                    <i className="fas fa-edit"></i>
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: "",
            cell: (row, index) => (
                <button
                    onClick={() => handleDelete(row.sku, index)}
                    className="text-blue-500 hover:text-blue-700"
                >
                    <i className="fas fa-trash"></i>
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
        {
            name: "",
            cell: (row) => (
                <button className="bg-wam-blue hover:text-blue-700"
                    onClick={() => handleDuplicate(row)}
                >
                    <i className="fas fa-clone"></i>
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '40px'
        },
    ];

    const totalPrice = (projectItems || []).reduce(
        (total, item) => total + (item?.price || 0),
        0
    ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const generateDocument = async (projectItems) => {
        try { 
            const payload = { projectItems, username };
            const blob = await generateDocumentService(payload); 
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'GeneratedDocument.docx';
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error generating document:', error);
        }
    };

    //useEffect(() => {
    //    const handleClickOutside = (event) => {
    //        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //            setIsDropdownOpen(false);
    //        }
    //    };

    //    document.addEventListener('mousedown', handleClickOutside);
    //    return () => {
    //        document.removeEventListener('mousedown', handleClickOutside);
    //    };
    //}, []);

    return (
        <div className="p-3 min-h-full">
            <h1 className="sr-only">Configuration Items</h1>
            {stateData && stateData.configurationModelId !== null && (
                <div
                    className={`grid grid-cols-1 items-start gap-2 ${selectedConfigInstanceData?.configurationInstanceId === "ProjectDetails"
                        ? "lg:grid-cols-12 lg:gap-3 min-h-[100vh]"
                        : "lg:grid-cols-5 lg:gap-3"
                        }`}
                >
                    {/* Configuration Instances */}
                    <div
                        className={`grid grid-cols-1 gap-4 min-h-[70vh] ${selectedConfigInstanceData?.configurationInstanceId === "ProjectDetails" ? "lg:col-span-2" : ""
                            }`}
                    >
                        <section aria-labelledby="ConfigurationInstanceSectionHeader">
                            <h2 className="sr-only" id="ConfigurationInstanceSectionHeader">
                                Configuration Instances
                            </h2>
                            <div className="overflow-hidden rounded-lg min-h-full">
                                <div className="overflow-hidden bg-wam-extralightgrey sm:rounded-md my-1">
                                    <ul role="list" className="divide-y-4 divide-wam-orange cursor-pointer">
                                        <ConfigurationInstance />
                                    </ul>
                                </div>
                            </div>
                        </section>
                    </div>

                    {/* Current Configuration Instance */}
                    <div
                        className={`${selectedConfigInstanceData?.configurationInstanceId === "ProjectDetails"
                            ? "lg:col-span-10"
                            : "lg:col-span-3"
                            } flex flex-col min-h-[70vh]`}
                    >
                        <section aria-labelledby="section-1-title" className="flex-1">
                            {selectedConfigInstanceData?.instanceType !== "Summary" && (
                                <div className="flex justify-between items-center">
                                    <h2 className="font-bold text-center text-2xl px-2 py-2" id="section-1-title">
                                        Select Resources 
                                    </h2>
                                    {/*{selectedConfigInstanceData?.configurationInstanceId === "ProjectDetails" && !isProjectDuplicate && projectItems.length > 0 &&(*/}
                                    {/*    <div className="mb-2 flex ">*/}
                                    {/*        <p className="bg-wam-red text-white">This is duplicate project considering it as new project.</p>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}

                                    {selectedConfigInstanceData?.configurationInstanceId === "Quotation"&& hasComputedValue && isEditButtonClick && (
                                        <div className="mb-2 flex float-right">
                                            <button
                                                onClick={handleUpdateResource}
                                                className="bg-wam-green text-white px-4 py-2 rounded-lg"
                                            >
                                                Update Resource
                                            </button>
                                        </div>
                                    )}

                                    {selectedConfigInstanceData?.configurationInstanceId === "Quotation" && hasComputedValue && !addToProjectClicked && !isEditButtonClick && (
                                        <div className="mb-2 flex float-right">
                                            <button
                                                onClick={handleAddToProject}
                                                className="bg-wam-green text-white px-4 py-2 rounded-lg"
                                            >
                                                Add to Project
                                            </button>
                                        </div>
                                    )}

                                    {selectedConfigInstanceData?.configurationInstanceId === "Quotation" && addToProjectClicked && hasComputedValue && !isEditButtonClick &&  (
                                        <div className="mb-2 flex float-right">
                                            <button
                                                onClick={handleViewInProject}
                                                className="bg-wam-green text-white px-4 py-2 rounded-lg"
                                            >
                                                View in Project
                                            </button>
                                            <button
                                                onClick={handleBackToAddProject}
                                                className="bg-wam-lightgrey text-white py-2 px-4 rounded-lg ml-2"
                                            >
                                                <i className="fas fa-arrow-left mr-1"></i>
                                                Add More Resources
                                            </button>
                                        </div>
                                    )}

                                    <div className="mb-2 flex">
                                        {selectedConfigInstanceData?.configurationInstanceId === "ProjectDetails" && projectItems.length > 0 && (
                                            <>
                                                <button
                                                    className="bg-wam-lightgrey text-white px-4 py-2 rounded-lg shadow hover:bg-wam-green transition mx-2"
                                                    onClick={() => generateDocument(projectItems)}
                                                >
                                                    Print
                                                </button>
                                                <button
                                                    onClick={handleBackToAddProject}
                                                    className="bg-wam-lightgrey text-white py-2 px-4 rounded-lg ml-2"
                                                >
                                                    <i className="fas fa-arrow-left mr-1"></i>
                                                    Add More Resources
                                                </button>
                                                <button
                                                    className="bg-wam-lightgrey text-white px-4 py-2 rounded-lg shadow hover:bg-wam-red transition mx-2"
                                                    onClick={openProjectCloseModal}
                                                >
                                                    Close
                                                </button>
                                                <button
                                                    className="bg-wam-lightgrey text-white px-4 py-2 rounded-lg shadow hover:bg-wam-green transition mx-2"
                                                    onClick={handleSaveClick}
                                                >
                                                    Save
                                                </button>
                                                {/* 
            <div className="relative inline-block text-left" ref={dropdownRef}>
                <button
                    className="bg-wam-lightgrey text-white px-4 py-2 rounded-lg shadow hover:bg-wam-green transition mx-2"
                    onClick={() => setIsDropdownOpen((prev) => !prev)}
                >
                    Save
                </button>

                {isDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-40 bg-wam-lightgrey border border-gray-200 rounded-lg shadow-lg z-10">
                        <button
                            onClick={() => {
                                if (!isSaveClicked) {
                                    handleSaveClick();
                                }
                            }}
                            className={`w-full text-left px-4 py-2 ${isSaveClicked ? 'text-gray-400 cursor-not-allowed' : 'hover:bg-wam-green'}`}
                            disabled={isSaveClicked}
                        >
                            Save
                        </button>
                        <button
                            onClick={handleRevisionClick}
                            className={`w-full text-left px-4 py-2 ${isSaveClicked ? 'hover:bg-wam-green' : 'text-gray-400 cursor-not-allowed'}`}
                            disabled={!isSaveClicked}
                        >
                            Revision
                        </button>
                    </div>
                )}
            </div>
            */}

                                            </>
                                        )}

                                        {selectedConfigInstanceData?.configurationInstanceId === "ProjectDetails" && (
                                            <button
                                                className="bg-wam-lightgrey text-white px-4 py-2 rounded-lg shadow hover:bg-wam-green transition mx-2"
                                                onClick={openProjectModal}
                                            >
                                                Open
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="overflow-hidden rounded-lg my-1 flex-1">
                                {stateData && stateData.configurationInstanceId !== null && (
                                    <div className="grid grid-cols-1">
                                        {selectedConfigInstanceData?.instanceType === "BOM" || selectedConfigInstanceData?.instanceType === "BOO" ? (
                                            <div className="grid grid-cols-1 gap-4">
                                                <ConfigurationTable
                                                    configurationColumns={selectedConfigInstanceData?.tableColumns?.values}
                                                    instanceType={selectedConfigInstanceData?.instanceType}
                                                    isLoading={isLoading}
                                                />
                                            </div>
                                        ) : (
                                            isConfigSummaryData === true && (
                                                    <div>
                                                        {selectedModel.configurationModelId === 'BPO2' && selectedConfigInstanceData?.configurationInstanceId === "Quotation" ? (
                                                            <div className="grid lg:grid-cols-1 gap-4 sm:grid-cols-1" style={{ width: '800px' }}>
                                                                <ConfigurationItem 
                                                                    setShowDurationTextInput={setShowDurationTextInput}
                                                                    showDurationTextInput={showDurationTextInput}    
                                                                />
                                                            </div>
                                                        ) : (
                                                              <div className="grid lg:grid-cols-1 gap-4 sm:grid-cols-1">
                                                                <ConfigurationItem />
                                                              </div>
                                                        )}
                                                    </div>

                                            )
                                        )}
                                    </div>
                                )}
                                {selectedConfigInstanceData?.configurationInstanceId === "ProjectDetails" && (
                                    <div className="p-4">
                                        <DataTable
                                            columns={columns}
                                            data={projectItems}
                                            pagination
                                            highlightOnHover
                                            striped
                                            paginationPerPage={recordsPerPage}
                                            paginationRowsPerPageOptions={[3, 5, 10, 15, 20]}
                                            onChangePage={(page) => setCurrentPage(page)}
                                        />
                                        {projectItems.length > 0 && (
                                            <div className="mt-4 text-right float-left">
                                                <span className="font-bold text-lg">
                                                    Total Price: ${totalPrice}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </section>
                    </div>


                    {/* Summary Information */}
                    {((selectedConfigInstanceData?.configurationInstanceId === "Quotation" || selectedConfigInstanceData?.configurationInstanceId === "ContactConsultant") && isConfigSummaryData === true) && (
                        <div className="grid grid-cols-1 gap-4 min-h-full">
                            <section aria-labelledby="ConfigurationSummarySectionHeader">
                                <h2 className="sr-only" id="ConfigurationSummarySectionHeader">
                                    Summary Information
                                </h2>
                                <div className="overflow-hidden rounded-lg min-h-full">
                                    {isConfigSummaryData === true && <ConfigurationSummary />}
                                </div>
                            </section>
                        </div>
                    )}
                </div>
            )}
            <ProjectCloseModal
                isOpen={isProjectCloseModalOpen}
                onClose={handleCloseWithoutSaving}
                onSaveAndClose={handleSaveAndClose}
                onCloseModel={handleclosemodel}
            />
            <ConfirmModal
                isOpen={showPopup}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            {/*<RevisionConfirmModal
                isOpen={showRevisionPopup}
                onRevisionConfirm={handleRevisionConfirm}
                onRevisionCancel={handleRevisionCancel}
            />*/}l
            <StatusModal
                isOpen={showStatusModal}
                message={statusMessage}
                onClose={handleCloseStatusModal}
            />
            <ProjectListModal
                isOpen={isProjectModalOpen}
                onClose={closeProjectModal}
                projects={projects}
            />
        </div>
    );




};

export default ItemSection;
