import { ChevronRightIcon } from "@heroicons/react/20/solid";
import {
    useQuery,
    useMutation,
    useQueryClient,
    useIsFetching,
} from "react-query";

import {
    getConfigurationInstances,
    setSelectedConfigurationInstance,
    getState,
} from "../../../services/Service";

function GlobalLoadingIndicator() {
    const isFetching = useIsFetching({
        predicate: (query) =>
            query.queryKey == "ConfigurationItems" && query.state.isFetching == true,
    });

    //return isFetching || getState().isLoading ? true : false;
    return getState().isLoading ? true : false;
}

export default function () {
    const queryClient = useQueryClient();
    // Queries
    const configurationInstances = useQuery(
        "ConfigurationInstances",
        () => getConfigurationInstances(),
        {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            onSuccess: () => {
                queryClient.invalidateQueries("IsConfigurationSummary");
                queryClient.invalidateQueries("SelectedConfigurationInstance");
            },
        }
    );

    const State = useQuery("State", () => getState, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
    });

    // Mutations
    const mutation = useMutation(setSelectedConfigurationInstance, {
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries("State");
            queryClient.invalidateQueries("SelectedConfigurationInstance");
            queryClient.invalidateQueries("ConfigurationItems");
            queryClient.invalidateQueries("SubGroups");
            queryClient.invalidateQueries("IsConfigurationSummary");
        },
    });

    const path = "../assets/images/configurationInstance/";

    const isLoading = GlobalLoadingIndicator();

    return (
        <>
            {configurationInstances.isLoading && (
                <div className="flex h-full justify-center items-center">
                    <svg
                        className="w-12 h-12 animate-spin text-indigo-400"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 4.75V6.25"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M17.1266 6.87347L16.0659 7.93413"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M19.25 12L17.75 12"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M17.1266 17.1265L16.0659 16.0659"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M12 17.75V19.25"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M7.9342 16.0659L6.87354 17.1265"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M6.25 12L4.75 12"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                        <path
                            d="M7.9342 7.93413L6.87354 6.87347"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </svg>
                </div>
            )}
            {!configurationInstances.isLoading &&
                configurationInstances.data &&
                configurationInstances.data
                    .sort(function (a, b) {
                        return a.order - b.order;
                    })
                    .map(
                        (configurationInstance) =>
                            configurationInstance.configurationInstanceId !== "Summary" && (
                                <li key={configurationInstance.configurationInstanceId}>
                                    <a
                                        disabled={isLoading}
                                        onClick={() => {
                                            if (!isLoading) {
                                                mutation.mutate({
                                                    configurationInstanceId:
                                                        configurationInstance.configurationInstanceId,
                                                });
                                            }
                                        }}
                                        className={
                                            State.data &&
                                                State.data.configurationInstanceId ==
                                                configurationInstance.configurationInstanceId
                                                ? isLoading
                                                    ? "block bg-wam-orange hover:bg-wam-orange cursor-not-allowed opacity-50"
                                                    : "block bg-wam-orange hover:bg-wam-orange"
                                                : isLoading
                                                    ? "block cursor-not-allowed opacity-50"
                                                    : "block hover:bg-wam-lightgrey"
                                        }
                                    >
                                        <div className="flex items-center px-2 py-2 sm:px-4">
                                            <div className="flex min-w-0 flex-1 items-center">
                                                <div className="flex-shrink-0">
                                                    <img
                                                        className="h-10 w-10 rounded-full"
                                                        src={
                                                            path +
                                                            `${configurationInstance.configurationInstanceIconPath}`
                                                        }
                                                        alt={
                                                            configurationInstance.ConfigurationInstanceDescription
                                                        }
                                                    />
                                                </div>
                                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                                                    <div>
                                                        <p className="truncate text-sm font-medium text-wam-black">
                                                            {
                                                                configurationInstance.configurationInstanceDescription
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <ChevronRightIcon
                                                    className="h-8 w-8 text-wam-black font-bold"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            )
                    )}

        </>
    );
}
