import React from 'react';

const ErrorComponent = ({ error, errorInfo }) => {
    return (
        <div>
            <h1>An error occurred</h1>
            <p>{error ? error.toString() : 'Unknown error'}</p>
            <pre>{errorInfo ? errorInfo.componentStack : 'No stack trace'}</pre>
        </div>
    );
};

export default ErrorComponent;
