import React, { createContext, useState } from 'react';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [globalState, setGlobalState] = useState({
        selectedOptions: {},
        selectedMultiSelectOptions: {},
        textValues: {}, 
        initialTextValues: {},
        SKU: null,
        ModelId: null,
        Price: null,
        resetActiveBreadcrumb: true
    });

    const updateGlobalState = (newState) => {
        setGlobalState((prevState) =>
            typeof newState === 'function' ? newState(prevState) : { ...prevState, ...newState }
        );
    };

    const resetTextBoxValue = (key) => {
        setGlobalState((prevState) => ({
            ...prevState,
            textValues: {
                ...prevState.textValues,
                [key]: prevState.initialTextValues[key] || "", 
            }
        }));
    };

    return (
        <GlobalStateContext.Provider
            value={{
                globalState,
                updateGlobalState,
                resetTextBoxValue,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};
