import { useQuery, useMutation, useQueryClient } from "react-query";
import ConfigurationDropdown from "./ConfigurationDropdown";
import ConfigurationButton from "./ConfigurationButton";
import ConfigurationText from "./ConfigurationText";
import ConfigurationImage from "./ConfigurationImage";

import { updateConfiguration, getConfigurationItems } from "../../../services/Service";

export default function ({ configurationColumns, instanceType, isLoading }) {
  const queryClient = useQueryClient();

  // Queries
  var configurationItems = useQuery(
    "ConfigurationItems",
    () => getConfigurationItems(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const mutation = useMutation({
    mutationFn: updateConfiguration,
    onSuccess: (data) => {
      queryClient.setQueryData(["ConfigurationItems"], data);
      queryClient.invalidateQueries("ConfigurationSummaryItems");
    },
  });

  function getValue(item, selectedValue, column) {
    if (selectedValue[column] === null) {
      return "-";
    } else if (selectedValue[column] !== undefined) {
      return selectedValue[column];
    } else if (selectedValue?.attributes[column] !== undefined) {
      return selectedValue?.attributes[column]?.value;
    } else {
      return item[column];
    }
  }

  return (
    <div className="px-2 sm:px-2 lg:px-2">
      {!isLoading && (
        <div className="overflow-x">
          <table className="table-auto overflow-x-scroll w-full block rounded">
            <thead key={"TABLEHEAD"} className="bg-wam-darkgrey">
              <tr key={"HEADERRow"}>
                <th
                  key={"HEADERDescription"}
                  scope="col"
                  className="text-center text-sm text-wam-white min-w-[400px] py-3 border-x-2 border-wam-darkgrey"
                >
                  Description
                </th>
                {configurationColumns &&
                  configurationColumns.length > 0 &&
                  configurationColumns.map((column) => (
                    <th
                      key={"HEADER" + column}
                      scope="col"
                      className="text-center text-sm text-wam-white min-w-[100px] py-3 border-x-2 border-wam-darkgrey"
                    >
                      {column}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody key={"TABLEBODY"}>
              {configurationItems.data &&
                Object.keys(configurationItems.data)
                  .sort(function (a, b) {
                    return a.order - b.order;
                  })
                  .map((cikey) => {
                    const configurationItem = configurationItems.data[cikey];
                    const selectedValue =
                      configurationItems.data[cikey].values[
                        configurationItems.data[cikey]
                          .computedSelectedValueOrDefault
                      ];
                    return (
                      configurationItem.visible && (
                        <tr
                          key={"ROW" + cikey}
                          className="bg-wam-white odd:bg-wam-lightgrey"
                        >
                          <td
                            className="whitespace-nowrap text-sm border-x-2 border-wam-darkgrey"
                            key={"CELL" + cikey}
                          >
                            {configurationItem.visible &&
                              configurationItem.editorType == "DropDown" && (
                                <ConfigurationDropdown
                                  configurationItem={configurationItem}
                                  mutation={mutation}
                                  showLables={false}
                                />
                              )}
                             {configurationItem.visible &&
                              configurationItem.editorType == "Button" && (
                               <ConfigurationButton
                                 configurationItem={configurationItem}
                                 mutation={mutation}
                                 showLables={false}
                                />
                              )}
                              
                            {configurationItem.editorType == "Text" && (
                              <ConfigurationText
                                configurationItem={configurationItem}
                                showLables={false}
                              />
                                    )}
                            {configurationItem.visible &&
                              configurationItem.editorType == "ImageArea" && (
                               <ConfigurationImage
                                 configurationItem={configurationItem}
                                 mutation={mutation}
                                 showLables={false}
                                />
                              )}
                          </td>

                          {selectedValue &&
                            configurationColumns &&
                            configurationColumns.length > 0 &&
                            configurationColumns.map((column, index) => (
                              <td
                                className="text-center whitespace-nowrap text-sm border-x-2 border-wam-darkgrey"
                                key={"CELL" + column}
                              >
                                {getValue(
                                  configurationItem,
                                  selectedValue,
                                  column
                                )}
                              </td>
                            ))}
                        </tr>
                      )
                    );
                  })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
