import { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../../../context/GlobalStateContext";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { getState, updateConfiguration, getSelectedConfigurationInstance } from "../../../services/Service";

export default function ConfigurationTextBox({ configurationItem, mutation1, value, onTextChange }) {
    const { globalState, updateGlobalState, resetTextBoxValue } = useContext(GlobalStateContext);
    const queryClient = useQueryClient();
    const [inputValue, setInputValue] = useState(value || "");

    useEffect(() => {
        if (globalState.textValues[configurationItem.classConfiguration] === undefined) {
            updateGlobalState({
                textValues: {
                    ...globalState.textValues,
                    [configurationItem.classConfiguration]: value || "",
                }
            });
        }

    }, [value, globalState.textValues, configurationItem.classConfiguration, updateGlobalState, inputValue]);

    useEffect(() => {
        const globalTextValue = globalState.textValues[configurationItem.classConfiguration];
        if (globalTextValue !== inputValue) {
            setInputValue(globalTextValue || value || "");
        }
    }, [value, globalState.textValues, configurationItem.classConfiguration, inputValue]);

    const { data: stateData } = useQuery("State", getState, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
    });
    const mutation = useMutation({
        mutationFn: updateConfiguration,
        onMutate: () => {
            queryClient.invalidateQueries("State");
        },
        onSuccess: (data) => {
           queryClient.setQueryData("ConfigurationItems", data);
            queryClient.invalidateQueries("ConfigurationSummaryItems");
            queryClient.invalidateQueries("State");
        },
    });
    
    const checkConflict = (key, value, selected) => {
        const conflict = {
            isConflict: true,
            key,
            value,
            selected,
        };

        queryClient.setQueryData("State", (oldState) => ({
            ...oldState,
            conflict,
        }));

        mutation.mutate({
            key: configurationItem.classConfiguration,
            value,
            selected,
        });
    };


    const handleChange = (event) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        onTextChange(newValue);
       
        updateGlobalState({
            textValues: {
                ...globalState.textValues,
                [configurationItem.classConfiguration]: newValue,
            },
        });
        checkConflict(configurationItem.classConfiguration, newValue, true);
    };

    return (
        <div key={"TEXTBOX" + configurationItem.classConfiguration} className="grid grid-cols-8 items-center pt-2 relative">
            <label className="block col-span-2 text-sm font-medium text-wam-black">
                {configurationItem.description}
            </label>

            <div className="mt-1 col-span-3 pt-1 relative group">
                <input
                    key={"INPUT" + configurationItem.classConfiguration}
                    type="text"
                    disabled={!configurationItem.enabled}
                    placeholder={`Enter ${configurationItem.description}`}
                    name={"INPUT" + configurationItem.classConfiguration}
                    id={configurationItem.id}
                    value={inputValue}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <div className="absolute bottom-full mb-1 left-0 flex items-center group-hover:flex">
                    <span className="absolute hidden group-hover:block bg-wam-black text-wam-white text-xs py-1 px-2 rounded-lg shadow-lg z-20 whitespace-nowrap">
                        {configurationItem.description}
                    </span>
                </div>
            </div>
        </div>
    );
}
