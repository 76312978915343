import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ItemSection from './pages/ItemSection';
import ModelSection from "./pages/ModelSection";
import { Header } from "./header/Header";
import { Footer } from "./footer/Footer";

const Layout = () => {
    return (
        <div className="min-h-full bg-wam-white">
            <Header />
            <main className="min-h-[70vh]">
                <Routes>
                    <Route path="/models" element={<ModelSection />} />
                    <Route path="/items" element={<ItemSection />} />
                    <Route path="*" element={<Navigate to="/models" replace />} />
                </Routes>
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
